import PropTypes from 'prop-types';
import React from 'react';

import {ICloudinaryImage, IProductOverview} from '../../types';

import './ProductRecommender.scss';
import ProductRecommenderManager from './ProductRecommenderManager';

export const IProductRecommenderProps = {
    className: PropTypes.string,
    classNames: PropTypes.string, // contentful misspelling
    contentType: PropTypes.oneOf(['productRecommenderBlock']),
    name: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    bodyCopy: PropTypes.string,
    disclaimer: PropTypes.object,
    anchorId: PropTypes.string,
    switchTextAndProduct: PropTypes.bool,
    hasDivider: PropTypes.bool,
    cta: PropTypes.string,
    ctaLink: PropTypes.string,
    singleColorBackground: PropTypes.string,
    whiteText: PropTypes.bool,
    backgroundAsset: ICloudinaryImage,
    mobileBackgroundAsset: ICloudinaryImage,
    productList: PropTypes.arrayOf(IProductOverview),
    productGroup: PropTypes.object,
    seeMoreLabel: PropTypes.object,
    seeLessLabel: PropTypes.object,
    revision: PropTypes.number,
};

export default function ProductRecommenderBlock(props) {
    const fields = props.document.fields;
    const entity = props.extraAttributes?.entity;
    const onClickCallback = props.onClickCallback;

    if (!fields) {
        return null;
    }

    let { productList, productGroup } = fields;

    if (!productList && !productGroup && entity) {
        const { relatedProducts, relatedProductGroup } = entity;

        if(relatedProductGroup) {
            const relatedGroupProducts = relatedProductGroup.fields.productList;

            productList = relatedGroupProducts;
        }

        if (!relatedProductGroup && relatedProducts) {
            productList = relatedProducts;
        }
    }

    if(!productList && productGroup) {
        const groupProducts = productGroup.fields.productList;

        if (groupProducts) {
            productList = groupProducts;
        }
    }

    return (
        <ProductRecommenderManager
            productList={productList}
            onClickCallback={onClickCallback}
            {...fields}
        />
    );
}

ProductRecommenderBlock.propTypes = {
    document: PropTypes.shape({
        fields: PropTypes.exact(IProductRecommenderProps),
    }),
    onClickCallback: PropTypes.func
};

