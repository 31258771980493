import React from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import { stripHtml } from '../../adapters/helpers/Utils';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import Block from '../../adapters/helpers/Block';
import { ComponentFactoryConstants } from '../../adapters/helpers/Constants'

import Heading from '../Heading/Heading'

import './ArticleListBlock.scss';
import ObLink from '../ObLink/ObLink';
import Icon from '../Icon/Icon';

const IArticleListProps = {
    contentType: PropTypes.oneOf([ComponentFactoryConstants.ArticleListBlock]),
    classNames: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    anchorId: PropTypes.string,
    category: PropTypes.object,
    articles: PropTypes.array,
    articleList: PropTypes.array,
    ctaLabel: PropTypes.string,
    ctaLink: PropTypes.object,
    isCategoryLanding: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.object),
};

export default function ArticleListBlock(props) {
    const { extraAttributes } = props;

    const block = new Block(props);
    const fields = block.getFields();
    const categoryName = fields?.category?.fields?.name || '';

    let articles = [];
    let isCategoryLanding = false;

    if (categoryName && extraAttributes?.articles) {
        articles = extraAttributes?.articles[categoryName]
    } else {
        articles = fields?.articleList;
        isCategoryLanding = true;
    }

    return (articles && articles.length > 0) ? (
        <ArticleList
            {...fields}
            anchorId={block.getAnchorId()}
            articles={articles}
            isCategoryLanding={isCategoryLanding}
        />
    ) : null;
}

ArticleListBlock.propTypes = {
    document: PropTypes.shape({
        fields: PropTypes.exact(IArticleListProps),
    }),
};

export function ArticleList(props) {
    const { classNames, className = classNames } = props;
    const { title, anchorId, articles, isCategoryLanding, ctaLabel, ctaLink} = props;

    const variationB = (articles.length <= 2);

    return (
        <div className={`ob-article-list ob-additional-reading ${isCategoryLanding ? 'ob-article-list--landing' : ''} ${variationB ? 'ob-additional-reading--wide' : ''} ${className || ''}`} id={`${anchorId}`}>
            <Heading className="ob-additional-reading__heading" tag={isCategoryLanding ? 'h3' : 'h2'}>
                {title}
            </Heading>

            <div className="ob-additional-reading__articles">
                <ul className="ob-additional-reading__list">
                    {articles.map((article, i) => {
                        const overview = article?.fields?.articleOverview;
                        const thumbnail = overview?.fields?.thumbnailImage;

                        return (
                            <li className="ob-additional-reading__item" key={i}>
                                <a className="ob-additional-reading__item-link event_internal_link"
                                   data-action-detail={stripHtml(overview?.fields?.title)}
                                   href={overview?.fields?.slug?.fields?.slug}>
                                    <div className="ob-additional-reading__item-image">
                                        {!!thumbnail && (
                                            <LazyLoad offset={100} placeholder={
                                                <picture className="ob-additional-reading__item-pic">
                                                    <img
                                                        className="ob-additional-reading__item-img"
                                                        alt={`${overview?.fields?.title} ${ComponentFactoryConstants.articleLink}`}
                                                        height={150}
                                                    />
                                                </picture>
                                            }>
                                                <picture className="ob-additional-reading__item-pic">
                                                    {variationB && (
                                                        <source media="(min-width: 1200px)" srcSet={ImageFactory.dpr(thumbnail, 'q_auto,f_auto,c_fill,w_460,h_260')} />
                                                    )}

                                                    <source media="(min-width: 426px)" srcSet={ImageFactory.dpr(thumbnail, 'q_auto,f_auto,c_fill,w_272,h_150')} />
                                                    <source srcSet={ImageFactory.dpr(thumbnail, 'q_auto,f_auto,c_fill,w_124,h_150')} />

                                                    <img
                                                        className="ob-additional-reading__item-img"
                                                        alt={`${ overview?.fields?.title } ${ ComponentFactoryConstants.articleLink }`}
                                                        src={ImageFactory.src(thumbnail, 'q_auto,f_auto,c_fill,w_460,h_260')}
                                                        srcSet={ImageFactory.dpr(thumbnail, 'q_auto,f_auto,c_fill,w_460,h_260')}
                                                        height={150}
                                                    />
                                                </picture>
                                            </LazyLoad>
                                        )}
                                    </div>

                                    <div className="ob-additional-reading__item-info">
                                        <div className="ob-additional-reading__item-title">
                                            {overview?.fields?.title}
                                        </div>

                                        {!!overview?.fields?.readTime && (
                                            <div className="ob-additional-reading__item-time">
                                                {overview.fields.readTime} min read
                                            </div>
                                        )}
                                    </div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {isCategoryLanding &&
                <ObLink tag={'a'} href={ctaLink?.fields?.slug} className={'ob-article-list__see-more-button'}>
                    <Icon className="ob-see-more-button__icon" roundedIcon={ComponentFactoryConstants.blue} name={ComponentFactoryConstants.chevronRight}/>
                    {ctaLabel}
                </ObLink>
            }
        </div>
    );
}

ArticleList.propTypes = IArticleListProps;
