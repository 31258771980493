export const CLOUDINARY_BASEURL = 'https://res.cloudinary.com/pgone/image/upload/';

export function buildImageUrl(imageId, transformations) {
  if (transformations) {
    return CLOUDINARY_BASEURL + transformations + '/' + imageId;
  } else {
    return CLOUDINARY_BASEURL + imageId;
  }
}

export function buildImageUrlByWidth(imageId, width = 'auto', transformations = 'c_limit', format = 'auto') {
  return [
    CLOUDINARY_BASEURL,
    'w_', width,
    (transformations ? ',' + transformations : ''),
    ',q_auto,f_', format,
    '/', imageId
  ].join('');
}

export function buildImageUrlByHeight(imageId, height = 'auto', transformations = 'c_limit', format = 'auto') {
  return [
    CLOUDINARY_BASEURL,
    'h_', height,
    (transformations ? ',' + transformations : ''),
    ',q_auto,f_', format,
    '/', imageId
  ].join('');
}

export function imageSrc(image, transformations) {
  return buildImageUrl(image.fields.assetId, transformations);
}

export function imageDpr(image, transformations = '') {
  const { assetId } = image.fields;

  if (!transformations) {
    return buildImageUrl(assetId);
  }

  return [
    buildImageUrl(assetId, transformations),
    buildImageUrl(assetId, transformations + ',dpr_1.5') + ' 1.5x',
    buildImageUrl(assetId, transformations + ',dpr_2.0') + ' 2x',
    buildImageUrl(assetId, transformations + ',dpr_3.0') + ' 3x',
  ].join(', ');
}

export default {
  buildImageUrl,
  buildImageUrlByWidth,
  buildImageUrlByHeight,
  src: imageSrc,
  dpr: imageDpr,
  CLOUDINARY_BASEURL: CLOUDINARY_BASEURL,
};
