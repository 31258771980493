import React, { useState,useLayoutEffect } from 'react';
import PropTypes from 'prop-types'
import Helmet from 'next/head'
import { Constants } from '../../adapters/helpers/Constants';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';

const ProductPageSEO = ({ productDetails}) => {
    let [rating, setRating] = useState(1);
    let [totalReview, setTotalReview] = useState(1);
    const timer = setTimeout(() => {
        if(typeof window!='undefined'){
        if(document.getElementsByClassName('bv_avgRating_component_container') && document.getElementsByClassName('bv_avgRating_component_container').length > 0) {
            setRating(rating => (rating, document.getElementsByClassName('bv_avgRating_component_container')[0]?.textContent))
        }
        if(document.getElementsByClassName('bv_numReviews_text') && document.getElementsByClassName('bv_numReviews_text').length > 0) {
            setTotalReview(totalReview => (totalReview, document.getElementsByClassName('bv_numReviews_text')[0]?.textContent?.replace(/[^0-9]/g,'')))
         }
        }
     }, 5000);
    useLayoutEffect(() => {
    }, [timer]);
    
     return  (        
        <Helmet>           
            <script
                type={Constants.typeLD}
                dangerouslySetInnerHTML={{
                    __html: `{
                        "@context": "${Constants.context}",
                        "@type": "${Constants.product}",
                        "brand": {
                            "@type": "Brand",
                            "name": "${Constants.brand}"
                        },
                        "name": "${productDetails?.name}",
                        "description": "${productDetails?.shortDescription || ''}",
                        "image": "${ImageFactory.buildImageUrl(productDetails?.mainAsset?.fields?.assetId) || ''}",
                        "sku": "${productDetails?.productVariants  && productDetails?.productVariants[0]?.fields?.sku}",
                        "gtin8": "${productDetails?.productVariants && productDetails?.productVariants[0]?.fields?.sku}",
                        "aggregateRating": {
                        "@type": "AggregateRating",
                        "bestRating": "5",
                        "worstRating": "1",
                        "ratingValue": "${rating ? rating:1}",
                        "reviewCount": "${totalReview!==undefined && totalReview!=='0' ? totalReview : 1}"
                        }                        
                        }`,
                }}
            />
        </Helmet>
    ) 
}

ProductPageSEO.propTypes = {
    productDetails: PropTypes.object.isRequired,
}

export default ProductPageSEO
