import getConfig from 'next/config';

import { MetaConstants } from './Constants';

const BASE_URL = getConfig()?.publicRuntimeConfig?.BASE_URL;

export function getCanonicalURL(path, scheme = MetaConstants.https) {
    const url = BASE_URL + path;
    return url.includes(scheme) ? url : scheme + url;
}

export function validURL(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}
