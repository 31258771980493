import PropTypes from 'prop-types';
import React from 'react';

export default function ErrorShortcut({title, link}) {
    if (!title || !link) {
        return null;
    }
    
    return (
        <a className="ob-outline-button ob-error-block-shortcut" href={link}>
            {title}
        </a>
    );
}

ErrorShortcut.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
};