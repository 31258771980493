import Icon from '../Icon/Icon';
import DotNav from '../DotNav/DotNav';
import PressQuote from './PressQuote';
import {mediaQueryHOC} from '../../adapters/helpers/Hooks';
import React, {Component} from 'react';
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types';
import {
    BrushConfiguratorConstants,
    ContentConstants,
    PressQuoteBlockConstants
} from '../../adapters/helpers/Constants';
import './PressQuoteBlock.scss';

class PressQuoteBlock extends Component {
    constructor(props) {
        super(props);
        this.block = new Block(props);
        this.pressQuotesContainerRef = null;
        this.className = this.valueOf(ContentConstants.classNames);
        this.anchorId = this.block.getAnchorId();
        this.pressQuotes = this.valueOf(PressQuoteBlockConstants.pressQuotes);
        this.PAGE_SCROLL_DELTA_THRESHOLD = 10;
        this.startTouchPoint = null;
        this.endTouchPoint = null;

        this.previousLabel = this.block.getFieldValue(PressQuoteBlockConstants.previousLabel)?.fields?.text;
        this.nextLabel = this.block.getFieldValue(PressQuoteBlockConstants.nextLabel)?.fields?.text;

        this.state = {
            currentIndex: 0,
        };
    }

    valueOf(name) {
        let value = undefined;
        if (name && this.block && this.block.getFieldValue(name) !== undefined) {
            value = this.block.getFieldValue(name);
        }
        return value;
    }

    handleDotNavClick(index) {
        if (this.pressQuotesContainerRef) {
            const items = this.pressQuotesContainerRef.querySelectorAll('.ob-pressQuotes-list__item');
            if (index >= items.length) {
                index = 0;
            } else if (index < 0) {
                index = items.length - 1;
            }

            // Workaround Chrome bug where the horizontal scroll is aborted if
            // the vertical scroll is already at the target position
            window.scrollTo(window.scrollX, Math.round(window.scrollY) + 1);

            items?.[index]?.scrollIntoView({
                behavior: BrushConfiguratorConstants.smooth,
                block: BrushConfiguratorConstants.center,
                inline: BrushConfiguratorConstants.center
            });

            this.setState({currentIndex: index});
        }
    }

    handleArrowNavigation(direction) {
        if (direction > 0) {
            this.handleDotNavClick(this.state.currentIndex + 1);
        } else {
            this.handleDotNavClick(this.state.currentIndex - 1);
        }
    }

    onTouchStart(event) {
        this.endTouchPoint = null;

        if (event.touches) {
            this.startTouchPoint = event.touches[0];
        }
    }

    onTouchEnd() {
        if (this.endTouchPoint != null && this.startTouchPoint != null) {
            let deltaX = this.startTouchPoint.screenX - this.endTouchPoint.screenX;
            let deltaY = this.startTouchPoint.screenY - this.endTouchPoint.screenY;

            if (!this.isVerticalScroll(deltaX, deltaY, this.PAGE_SCROLL_DELTA_THRESHOLD)) {
                this.handleArrowNavigation(deltaX);
            }
        }
    }

    isVerticalScroll(deltaX, deltaY, deltaThreshold = this.PAGE_SCROLL_DELTA_THRESHOLD) {
        let moveX = Math.abs(deltaX) > deltaThreshold ? Math.abs(deltaX) : 0;
        let moveY = Math.abs(deltaY) > deltaThreshold ? Math.abs(deltaY) : 0;

        return moveY > moveX;
    }

    onTouchMove(event) {
        if (event.touches) {
            this.endTouchPoint = event.touches[0];
        }
    }

    render() {
        return (
            <section
                className={
                    `ob-pressQuoteBlock content ${this.className ? this.className : ''}`
                }
                id={this.anchorId}
                onTouchEnd={this.onTouchEnd.bind(this)}
                onTouchMove={this.onTouchMove.bind(this)}
                onTouchStart={this.onTouchStart.bind(this)}
            >
                <div className={'ob-pressQuotes-container'}>
                    <ul className="ob-pressQuotes-list"
                        ref={(ref) => this.pressQuotesContainerRef = ref}>
                        {this.pressQuotes.map((quote, i) => (
                            <li className="ob-pressQuotes-list__item" key={quote.name || i}>
                                <PressQuote
                                    isMedium={this.props.isMedium}
                                    isXSmall={this.props.isXSmall}
                                    isXLarge={this.props.isXLarge}
                                    quote={quote}
                                />
                            </li>
                        ))}
                    </ul>
                    {(this.pressQuotes.length > 1) && (
                        <>
                        <div className={'ob-pressQuotes__arrow-container'}>
                            <button className={'ob-pressQuotes__arrow ob-pressQuotes__arrow-left event_button_click'} data-action-detail={this.state.currentIndex} aria-label={this.previousLabel} onClick={() => {
                                this.handleArrowNavigation(-1)
                            }}>
                                <Icon name={PressQuoteBlockConstants.iconArrowLeft} size="2"/>
                            </button>
                            <button className={'ob-pressQuotes__arrow ob-pressQuotes__arrow-right event_button_click'} data-action-detail={this.state.currentIndex}  aria-label={this.nextLabel} onClick={() => {
                                this.handleArrowNavigation(1)
                            }}>
                                <Icon name={PressQuoteBlockConstants.iconArrowRight} size="2"/>
                            </button>
                        </div>
                        <DotNav className="ob-pressQuoteItem__nav"
                            count={this.pressQuotes.length}
                            current={this.state.currentIndex}
                            color="white"
                            onClick={this.handleDotNavClick.bind(this)}
                        />
                        </>
                    )}
                </div>
            </section>
        );
    }
}

const classPressQuote = mediaQueryHOC(PressQuoteBlock);
export default classPressQuote;
// This export is for unit testing (do not remove) :
export const PressQuoteBlockTest = PressQuoteBlock;

PressQuoteBlock.propTypes = {
    pressQuotes: PropTypes.array,
    isXSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isXLarge: PropTypes.bool,
};
