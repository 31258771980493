import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import Image from '../Image/Image';
import ObLink from '../ObLink/ObLink';
import Icon from '../Icon/Icon';
import {createHtmlFriendlyId, getReviewAndRatingId, stripHtml} from '../../adapters/helpers/Utils';
import RichText from '../RichText/RichText';
import { LegacyProductHighlightsConstants } from '../../adapters/helpers/Constants';
import { mediaQueryHOC, updateCurrentProductIndex } from '../../adapters/helpers/Hooks';
import { getColorStyle } from '../../adapters/helpers/Utils';
import Button from '../Button/Button';
import '../ProductHighlights/ProductHighlights.scss';
import './LegacyProductHighlights.scss';
import {handleColorNameFormat} from '../../adapters/helpers/Utils'

function LegacyProductHighlights(props) {
    const {extraAttributes, isMedium, document:doc} = props;
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);
    const [currentCarouselImageIndex, setCurrentCarouselImageIndex] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [_, setGlobalState] = updateCurrentProductIndex();
    const entity = extraAttributes.entity;
    const productOverview = entity.productOverview.fields;
    const productVariants = productOverview?.productVariants;
    const legacyAssets = entity?.legacyAssets || [];
    const [carouselAssets, setCarouselAssets] = useState(legacyAssets);
    const productHighlights = entity.productHighlights;
    const labels = doc.fields;
    const pressQuote = productOverview?.pressQuote?.fields;
    const backgroundImage = doc.fields.background;
    const startingAtButtonLabel = productOverview?.buyNowLabel?.fields.text;
    const anchorId = doc.fields.anchorId;
    let reviewAndRatingId = getReviewAndRatingId(entity.productOverview);
    const seeLessLabel = doc?.fields?.seeLessLabel?.fields?.text.toUpperCase();
    const seeMoreLabel = doc?.fields?.seeMoreLabel?.fields?.text.toUpperCase();
    const nextLabel = doc?.fields?.nextLabel?.fields?.text;
    const previousLabel = doc?.fields?.previousLabel?.fields?.text.toUpperCase();
    const productVariantAttributeTypes = entity?.productVariantAttributeTypes || [];
    const thumbnailCarouselRef = useRef();
    const comingSoonText = productOverview?.comingSoonLabel?.fields?.text || '';
    const comingSoonLink = productOverview?.comingSoonLink || '';
    const buyNowDeactivated = productOverview?.deactivateBuyNowButton || false;

    const TruncatedDescription = ({className, text}) => {
        const [seeLess, setSeeLess] = useState(true);
        const firstPeriodIndex = text.indexOf('.');

        // If there is no period or the text contains a single sentence.
        if (firstPeriodIndex === -1 || firstPeriodIndex + 1 === text.length) {
            return <div className={className} dangerouslySetInnerHTML={{__html: text}}></div>;
        }

        return (
            <div className={className}>
                <div dangerouslySetInnerHTML={{__html: seeLess ? `${text.slice(0, firstPeriodIndex + 1)}` : text}}></div>

                <ObLink ariaExpanded={seeLess ? false : true} className="primaryGrey ob-product-highlights__see-more" onClick={() => setSeeLess(!seeLess)} tag={LegacyProductHighlightsConstants.button}>
                    <Icon name={seeLess? LegacyProductHighlightsConstants.plus : LegacyProductHighlightsConstants.minus6} roundedIcon={LegacyProductHighlightsConstants.blue} viewBox={seeLess ? 10 : 6}/>
                    {seeLess ? seeMoreLabel : seeLessLabel}
                </ObLink>
            </div>
        );
    };

    const renderHighlightsHeader = () => {
        let ele ='';
        if(isMedium) {
            ele = document.getElementById('ob-product-subnav-scroll');            
        }
        return (
            <div className={'ob-product-highlights__header'} style={{marginTop: ele?.offsetHeight+'px'}}>
                <p className={'ob-product-highlights__surtitle'}>{productOverview.surtitle}</p>
                <Heading tag="h2" className={'ob-product-highlights__title'}>{productOverview.title}</Heading>
                <div data-bv-show="inline_rating" data-bv-product-id={reviewAndRatingId}></div>
            </div>
        )
    };

    useEffect(() => {
        setGlobalState({currentProductId: selectedVariantIndex})
        if (productVariants[selectedVariantIndex]?.fields?.legacyAssets) {
            setCarouselAssets(productVariants[selectedVariantIndex]?.fields?.legacyAssets);
        } else if (legacyAssets.length > 1) {
            setCarouselAssets(legacyAssets);
        }
    }, [selectedVariantIndex]);

    const renderColorButtons = () => {
        return (
            <div className={'ob-product-highlights__color-ctn'}>
                {(productVariants.some(variant => variant?.fields?.color !== undefined)) &&
                <p className={'ob-product-highlights__label'}>{labels?.colorLabel}</p>
                }
                <ul className={'ob-product-highlights__colors'}>
                    {productVariants.map((variant, index) => {
                        return (variant?.fields?.color) ?
                            <li className="ob-product-highlights__selected-colors-item" key={index}>
                                <button
                                    style={getColorStyle(variant.fields.color)}
                                    className={`ob-product-highlights__color event_buy_now_choose_product ${index === selectedVariantIndex ? LegacyProductHighlightsConstants.isActive : LegacyProductHighlightsConstants.empty}`}
                                    onClick={() => setSelectedVariantIndex(index)}
                                    data-action-detail={variant?.fields?.color?.fields?.name}
                                    aria-pressed={index === selectedVariantIndex ? true : false}
                                    >
                                    <span className="visuallyhidden">{variant?.fields?.color?.fields?.name}</span>
                                </button>
                                {variant?.fields?.color && <div className="color-container-pdp"><p className={index === selectedVariantIndex ? 'ob-accessibility-highlighted' : 'ob-accessibility'}>{handleColorNameFormat(variant?.fields?.color?.fields?.name)}</p></div>}
                            </li> : '';
                    })}
                </ul>
            </div>
        )
    };

    const [showMore, setShowMore] = useState(false);

    const highlightList = (productHighlights) ? Object.keys(productHighlights).slice(0, 3).map(key => {
        return (
            <li key={key} className={'ob-product-highlights__highlights-list-item'}>
                <RichText document={productHighlights[key]} />
            </li>
        )}
    ) : [];

    const highlightListMore = (productHighlights) ? Object.keys(productHighlights).slice(3).map(key => {
        return (
            <li key={key} className={`ob-product-highlights__highlights-list-item more ${showMore}`}>
                <RichText document={productHighlights[key]} />
            </li>
        )}
    ) : [];

    const highlightLength = () => {
        // Return true if highlight is superior than 2 to display see more button
        return productHighlights && Object.keys(productHighlights).length > 3;
    };

    const showMoreButton = () => {
        let button;

        if (showMore) {
            button = (
                <ObLink ariaExpanded={true} className="primaryGrey ob-product-highlights__see-more event_button_click" tag={LegacyProductHighlightsConstants.button} dataActionDetail={seeLessLabel} onClick={handleShowMore}>
                    <Icon name={LegacyProductHighlightsConstants.minus6} roundedIcon={LegacyProductHighlightsConstants.blue} viewBox={6}/>
                    {seeLessLabel.toUpperCase()}
                    <span className="visuallyhidden">{LegacyProductHighlightsConstants.highlights}</span>
                </ObLink>
            )
        } else {
            button = (
                <ObLink ariaExpanded={false} className="primaryGrey ob-product-highlights__see-more event_button_click" tag={LegacyProductHighlightsConstants.button} dataActionDetail={seeMoreLabel} onClick={handleShowMore}>
                    <Icon name={LegacyProductHighlightsConstants.plus} roundedIcon={LegacyProductHighlightsConstants.blue} viewBox={10}/>
                    {seeMoreLabel.toUpperCase()}
                    <span className="visuallyhidden">{LegacyProductHighlightsConstants.highlights}</span>
                </ObLink>
            );
        }

        return button;
    };


    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const renderShopByPack = (productVariants) => {
        return (
            <div className={'ob-product-highlights__dropdown-ctn'}>
                <ul className={'ob-product-highlights__dropdown-list'}>
                    {productVariantAttributeTypes.map((productVariantAttributeType, index) => (
                        <li key={index} className={'ob-product-highlights__dropdown-list-item'}>
                            <label htmlFor={createHtmlFriendlyId(productVariantAttributeType?.fields?.name)} className={'ob-product-highlights__label ob-product-highlights__dropdown-label'}>{productVariantAttributeType?.fields?.description?.fields?.text}</label>
                            <div className={'ob-product-highlights__dropdown-list-item-ctn'}>
                                <select
                                    id={createHtmlFriendlyId(productVariantAttributeType?.fields?.name)}
                                    className={'ob-product-highlights__dropdown-select'}
                                    onBlur={(event) => setSelectedVariantIndex(parseInt(event.currentTarget.value, 10))}
                                >
                                    {productVariants.map((variant, variantIndex) => {
                                            let productVariantAttributes = variant?.fields?.productVariantAttributes;

                                            if (productVariantAttributes != null && productVariantAttributes.length > index) {
                                                if (productVariantAttributeType?.fields?.name === productVariantAttributes[index]?.fields?.productVariantAttributeTypeReference?.fields?.name) {
                                                    return (
                                                        <option key={variantIndex} value={variantIndex}>
                                                            {variant?.fields?.productVariantAttributes[index]?.fields?.description?.fields?.text}
                                                        </option>
                                                    )
                                                }
                                            } else {
                                                console.info('Product Variant Attr Type ' + productVariantAttributeType?.fields?.name + ' missing at index ' + index  + ' for variant ' + variant?.fields?.name);
                                                return ('');
                                            }

                                    })}
                                </select>
                                <div className={'ob-product-highlights__dropdown-icon'}>
                                    <Icon name={'chevronDown'} size={2} />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    };

    const handleCarouselSlideChange = ({position = '', index = -1}) => {
        if(thumbnailCarouselRef.current) {
            let carouselIndex = currentCarouselImageIndex;
            if (position === LegacyProductHighlightsConstants.prev && carouselIndex > 0) {
                carouselIndex--;
            } else if (position === LegacyProductHighlightsConstants.next && carouselIndex < carouselAssets?.length - 1) {
                carouselIndex++;
            } else if (index > -1) {
                carouselIndex = index;
            }
            setCurrentCarouselImageIndex(carouselIndex);

            const thumbnailChildren = thumbnailCarouselRef.current.children;
            scrollElemIntoView(thumbnailChildren[carouselIndex]);

        }
    };

    const scrollElemIntoView = (elem) => {
        if (!elem) {
            return;
        }
        elem.scrollIntoView({
            behavior: LegacyProductHighlightsConstants.smooth,
            block: LegacyProductHighlightsConstants.nearest,
            inline: LegacyProductHighlightsConstants.center
        })
    };

    let startTouchPoint = null;
    let endTouchPoint = null;

    const onTouchStart = event => {
        endTouchPoint = null;

        if (event.touches) {
            startTouchPoint = event.touches[0];
        }
    };

    const onTouchEnd = () => {
        if (endTouchPoint != null && startTouchPoint != null) {
            let deltaX = startTouchPoint.screenX - endTouchPoint.screenX;
            let deltaY = startTouchPoint.screenY - endTouchPoint.screenY;

            if (deltaX > 0 && !isVerticalScroll(deltaX, deltaY)) {
                handleCarouselSlideChange({position: LegacyProductHighlightsConstants.next})
            } else {
                handleCarouselSlideChange({position: LegacyProductHighlightsConstants.prev})
            }
        }
    };

    const onTouchMove = event => {
        if (event.touches) {
            endTouchPoint = event.touches[0];
        }
    };

    const isVerticalScroll = (deltaX, deltaY, deltaThreshold = 10) => {
        let moveX = Math.abs(deltaX) > deltaThreshold ? Math.abs(deltaX) : 0;
        let moveY = Math.abs(deltaY) > deltaThreshold ? Math.abs(deltaY) : 0;

        return moveY > moveX;
    };

    const renderCarousel = () => {
        return (
            <div className={'ob-product-highlights__carousel'}>
                <ul className={'ob-product-highlights__carousel-main-list'} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove}>
                    { carouselAssets.map((carouselMainAsset, index) => (
                        <li
                            key={index}
                            data-index={index}
                            className={`ob-product-highlights__carousel-main-list-item ${currentCarouselImageIndex === index ? '--is-active' : ''}`}
                        >
                            <Image image={carouselMainAsset} alt={carouselAssets?.[index]?.fields?.alternateText} />
                        </li>
                    ))}
                </ul>

                <div className={'ob-product-highlights__carousel-thumbnail-ctn'}>
                    <ul className={'ob-product-highlights__carousel-thumbnail-list'} ref={thumbnailCarouselRef}>
                        { carouselAssets.map((carouselThumbnailItem, index) => (
                            <li
                                key={index}
                                className={`ob-product-highlights__carousel-thumbnail-list-item ${carouselThumbnailItem?.imageRendition?.aspectRatio !== 1 ? 'ob-product-highlights__carousel-thumbnail-list-item--not-square' : ''} ${carouselAssets?.length <= 3 ? 'ob-product-highlights__carousel-thumbnail-list-item--not-full' : ''}`}>
                                <button
                                    type={'button'}
                                    aria-label={carouselAssets?.[index]?.fields?.alternateText}
                                    className={`ob-product-highlights__carousel-thumbnail-button ${currentCarouselImageIndex === index ? '--is-active' : ''}`}
                                    onClick={() => handleCarouselSlideChange({index: index})}
                                >
                                    <Image image={carouselThumbnailItem} />
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button
                        type={'button'}
                        className={`ob-product-highlights__carousel-btn ob-product-highlights__carousel-btn--prev ${currentCarouselImageIndex === 0 ? '--is-disabled': ''}`}
                        onClick={() => handleCarouselSlideChange({ position: LegacyProductHighlightsConstants.prev })}
                        aria-label={previousLabel}
                        tabIndex={currentCarouselImageIndex === 0 ? -1 : null}
                        disabled={currentCarouselImageIndex === 0 ? true : false}                                        
                    >
                        <Icon name={'ovalArrowRight'} rotate={180} size={2.8} ariaHidden={'true'} />
                    </button>
                    <button
                        type={'button'}
                        className={`ob-product-highlights__carousel-btn ob-product-highlights__carousel-btn--next ${currentCarouselImageIndex === carouselAssets?.length - 1 ? '--is-disabled' : ''}`}
                        onClick={() => handleCarouselSlideChange({ position: LegacyProductHighlightsConstants.next })}
                        aria-label={nextLabel}
                        tabIndex={currentCarouselImageIndex === carouselAssets?.length - 1 ? -1 : null}
                        disabled={currentCarouselImageIndex === carouselAssets?.length - 1 ? true : false}
                    >
                        <Icon name={'ovalArrowRight'} size={2.8} ariaHidden={'true'} />
                    </button>
                </div>
            </div>
        )
    };

    const renderTextContent = () => {
        return (
            <div className={'ob-product-highlights__text-content'}>
                {!isMedium && renderHighlightsHeader()}
                {isMedium && productVariants && renderColorButtons()}
                <TruncatedDescription
                    className={'ob-product-highlights__description'}
                    text={productOverview.description}
                />
                {pressQuote &&
                <div className={'ob-product-highlights__quote'}>
                    <span className={'ob-product-highlights__quote-text'}>{pressQuote.quote}</span>
                    <Image image={pressQuote.pressLogo}/>
                </div>
                }
                {productHighlights &&
                <div className={'ob-product-highlights__highlights-ctn'}>
                    <p className={'ob-product-highlights__label'}>{labels.highlightsLabel}</p>
                    <ul className={'ob-product-highlights__highlights-list'} id={'ob-highlights-ctn'}>
                        {highlightList}
                        {showMore &&
                        highlightListMore
                        }
                    </ul>
                    {highlightLength() && showMoreButton()}
                </div>
                }
                {!isMedium && productVariants && productVariants?.some(variant => variant?.fields?.color !== undefined) && renderColorButtons()}

                { productVariants && productVariantAttributeTypes.length > 0 && productVariants[selectedVariantIndex]?.fields?.productVariantAttributes && renderShopByPack(productVariants) }

                { startingAtButtonLabel && !buyNowDeactivated && productVariants[selectedVariantIndex]?.fields?.sku &&
                    <Button size={'medium-long'}
                            className='event_buy_now'
                            dataActionDetail={stripHtml(productOverview.title)}
                            onClick={event => props.onClickCallback(event)}
                            sku={productVariants[selectedVariantIndex]?.fields?.sku}>
                        {startingAtButtonLabel}
                    </Button>
                }

                {buyNowDeactivated &&
                    <Button
                        tag={'a'}
                        disabled={comingSoonLink === ''}
                        href={comingSoonLink}
                        size={'medium-long'}
                        target={'_blank'}
                    >
                        {comingSoonText}
                    </Button>
                }
            </div>
        )
    };

    if(productVariants &&  productVariants[0]?.fields?.mainAsset?.fields){
        productVariants[0].fields.mainAsset.fields['isProductPage'] = true;
    }else if(productOverview?.mainAsset?.fields){
        productOverview.mainAsset.fields['isProductPage'] = true;
    }
    return (
        <div className={'ob-product-highlights ob-legacy-product-highlights'} id={anchorId}>
            <Image image={backgroundImage}>
                <div className={'ob-product-highlights__ctn'}>
                    {isMedium && renderHighlightsHeader()}
                    {carouselAssets.length > 1 ? (
                        renderCarousel()
                    ) : (
                        <ul className={'ob-product-highlights__img-ctn'}>
                            { productVariants ?
                                productVariants.map((variant, index) => (
                                    <li className={`ob-product-highlights__img ${index === selectedVariantIndex ? LegacyProductHighlightsConstants.isActive : LegacyProductHighlightsConstants.empty}`} key={index}>
                                        {variant.fields.mainAsset ? (
                                            <Image image={variant.fields.mainAsset} />
                                        ) : (
                                            <Image image={productOverview.mainAsset} />
                                        )}
                                    </li>
                                )) : (
                                    <li>
                                        <Image image={productOverview.mainAsset} />
                                    </li>
                                )}
                        </ul>
                    )}
                    {!isMedium && renderTextContent()}
                </div>
            </Image>
            {isMedium && renderTextContent()}
        </div>
    )
}

export default mediaQueryHOC(LegacyProductHighlights);
// This export is for unit testing (do not remove) :
export const LegacyProductHighlightsTest = LegacyProductHighlights;

LegacyProductHighlights.propTypes = {
    extraAttributes: PropTypes.object,
    isMedium: PropTypes.bool,
    document: PropTypes.object,
    onClickCallback: PropTypes.func,
    className: PropTypes.string,
    text: PropTypes.string,
};
