import React, {Component} from 'react';
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import {SideBySideContainerConstants} from '../../adapters/helpers/Constants';
import {createHtmlFriendlyId} from '../../adapters/helpers/Utils';
import {mediaQueryHOC} from '../../adapters/helpers/Hooks';
import './SideBySideContainer.scss';
import Disclaimer from '../Disclaimer/Disclaimer';

class SideBySideContainer extends Component {

    constructor(props) {
        super(props);
        this.block = new Block(props);
    }

    renderTile(block, index) {
        const blockObj = new Block(block);
        const tileId = this.getTileId(blockObj);
        blockObj.setFieldValue(SideBySideContainerConstants.anchorId, tileId);

        const sideIndicator = (index % 2 == 0) ? SideBySideContainerConstants.left : SideBySideContainerConstants.right;

        return (
            <div key={tileId} className={`ob-side-by-side__column ob-side-by-side__${sideIndicator}-side`}>
                {blockObj.renderBlockFromDocument()}
            </div>
        );
    }

    getTileId(blockObj) {
        return SideBySideContainerConstants.sideBySide + blockObj.getAnchorId();
    }

    render() {
        let className = 'ob-side-by-side ' + this.props.className;
        const containerBlocks = this.block.getFieldValue(SideBySideContainerConstants.blocks);
        const title = this.block.getFieldValue(SideBySideContainerConstants.title);
        const disclaimer = this.block.getFieldValue(SideBySideContainerConstants.disclaimer);
        const name = this.block.getFieldValue(SideBySideContainerConstants.name);
        const hasDivider = this.block.getFieldValue(SideBySideContainerConstants.hasDivider);
        const anchorId = this.block.getFieldValue(SideBySideContainerConstants.anchorId) || createHtmlFriendlyId(name);
        const flipOnMobile = this.block.getFieldValue(SideBySideContainerConstants.flipOnMobile) || false;
        let containerBlocksCopyMobile = [...containerBlocks];

        if (flipOnMobile && containerBlocksCopyMobile.length >= 2) {
            // this works best with side by sides that only have 2 elements
            for (let i = 0; i < containerBlocksCopyMobile.length; i++) {
                // if element is on the right, swap with previous element
                if (i % 2 === 1) {
                    [containerBlocksCopyMobile[i - 1], containerBlocksCopyMobile[i]] = [containerBlocksCopyMobile[i], containerBlocksCopyMobile[i - 1]];
                }
            }
        }


        return (
            <div className={className} id={anchorId}>
                {title &&
                    <div className={'ob-side-by-side__title-wrapper'}>
                        <Heading tag="h2">{title}</Heading>
                        <p className={'ob-side-by-side__subtitle'}>{this.block.getFieldValue(SideBySideContainerConstants.description)}</p>
                    </div>
                }
                <div className={`ob-side-by-side__wrapper ${hasDivider ? 'ob-side-by-side__wrapper--has-divider' : ''}`}>
                    { flipOnMobile ?
                        this.props.isMedium ?
                            containerBlocksCopyMobile && containerBlocksCopyMobile.map((block, index) => {
                                return this.renderTile(block, index);
                            }) :
                            containerBlocks && containerBlocks.map((block, index) => {
                                return this.renderTile(block, index);
                            }) :
                        containerBlocks && containerBlocks.map((block, index) => {
                            return this.renderTile(block, index);
                        })
                    }
                </div>
                {disclaimer &&
                    <Disclaimer document={disclaimer} />
                }
            </div>
        );
    }
}

SideBySideContainer.propTypes = {
    className: PropTypes.string,
    isMedium: PropTypes.bool
};

export default mediaQueryHOC(SideBySideContainer)
// This export is for unit testing (do not remove) :
export const SideBySideContainerTest = SideBySideContainer;
