import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload';
import throttle from 'lodash/throttle';
import Head from 'next/head'
import { useIsomorphicLayoutEffect } from '../../../adapters/helpers/Hooks';
import { getBackgroundImage, roundUpTheSize } from './BackgroundImage.helpers'
import ImageFactory from '../../../adapters/cloudinary/ImageFactory';
import { mediaQueryHOC } from '../../../adapters/helpers/Hooks'
import { ImageConstants } from '../../../adapters/helpers/Constants';

import './BackgroundImage.scss';

function BackgroundImage(props) {
    const {backgroundOffsetTop: marginTop, backgroundPosition, alt = null, children = null, customStyles = null, ariaHidden = false} = props;
    const paddingTop = `${ 100 / props.widthToHeightRatio }%`;
    const isPreload = props.isPreload ? props.isPreload : false;
    const isBannerImage = props.isBannerImage ? props.isBannerImage : false;
    const nodeRef = useRef(null);
    const placeholderRef = useRef(null);
    const [width, setWidth] = useState(ImageConstants.defaultImageWindWidth);
    const [customClassName, setCustomClassName] = useState('');
    const mobileassertId = props?.preloadImage?.assetId ? props.preloadImage.assetId : props.imageId;
    const mobileImageUrl = ImageFactory.buildImageUrlByWidth( mobileassertId, ImageConstants.defaultImageWindWidth, props.transformations, props.format, props.isMedium)
    const [url, setUrl] = useState(`url(${mobileImageUrl})`);
    function updateWidth() {
        const node = nodeRef.current || placeholderRef.current;
        if (node) {
            setWidth(roundUpTheSize(node.getBoundingClientRect().width));
        }
    }

    useEffect(() => {
        if (width != 0) {
            // Set background width*2 for mobile because the background is larger than the with parent
            setUrl(getBackgroundImage(props.imageId, isPreload && isBannerImage && window.innerWidth<400 ? 450  : props.isMedium ? width*2: width, props.transformations, props.format, props.isMedium));
        }
     }, [width, props.imageId, props.isMedium]);


    useEffect(
        () => {
            const handleResize = throttle(updateWidth, 100);

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                handleResize.cancel();
            };
        },
        []
    );

    useEffect(
        () => {
            setCustomClassName(props.customClassName);
        }, [props.customClassName]
    );

    useIsomorphicLayoutEffect(
        updateWidth,
        []
    );


    return (
        <React.Fragment>
            {isPreload && isBannerImage ?  
            <React.Fragment>
            <Head>   
             <link rel="preload" href={mobileImageUrl} as="image" />
            </Head>
            <div
                className={`ob-background-wrapper ${width < ImageConstants.defaultDesktopWidth ? 'ob-background-wrapper-visible':''} ${props.className ? props.className : ''} ${customClassName ? customClassName : ''}`}
                ref={nodeRef}
                aria-hidden={ariaHidden}
                style={{
                    marginTop, paddingTop, backgroundPosition,
                       backgroundImage: url && width !=0 && width > ImageConstants.defaultImageWindWidth ? url : `url(${mobileImageUrl})`,
                    ...customStyles
                }}>
                {alt &&
                    <span role="img" aria-label={alt}></span>
                }
                {children}
            </div>
            </React.Fragment>
            :   <LazyLoad offset={500}
            placeholder={
                <div
                    className={`ob-background-wrapper ${props.className ? props.className : ''} ${customClassName ? customClassName : ''}`}
                    ref={placeholderRef}
                    aria-hidden={ariaHidden}
                    style={{
                        marginTop, paddingTop, backgroundPosition
                    }}
                />
            }
        >
            <div
                className={`ob-background-wrapper ${props.className ? props.className : ''} ${customClassName ? customClassName : ''}`}
                ref={nodeRef}
                aria-hidden={ariaHidden}
                style={{
                    marginTop, paddingTop, backgroundPosition,
                    backgroundImage: url && width !=0 ? url : mobileImageUrl,
                    ...customStyles
                }}>
                {alt &&
                    <span role="img" aria-label={alt}></span>
                }
                {children}
            </div>
        </LazyLoad>}
        </React.Fragment>
      
    );
}

export default mediaQueryHOC(BackgroundImage);

BackgroundImage.defaultProps = {
    transformations: 'c_limit',
    fragments: [{ minWidth: 0, size: 1 }],
    widthToHeightRatio: 1
}

BackgroundImage.propTypes = {
    imageId: PropTypes.string,
    transformations: PropTypes.string,
    className: PropTypes.string,
    customClassName: PropTypes.string,
    fragments: PropTypes.array,
    widthToHeightRatio: PropTypes.number,
    children: PropTypes.node,
    backgroundOffsetTop: PropTypes.string,
    backgroundPosition: PropTypes.string,
    customStyles: PropTypes.object,
    format: PropTypes.any,
    isMedium: PropTypes.bool,
    ariaHidden: PropTypes.bool,
    alt: PropTypes.string,
    isPreload: PropTypes.bool,
    isBannerImage: PropTypes.bool,
    preloadImage: PropTypes.any
}

