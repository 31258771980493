import Block from '../../adapters/helpers/Block';
import React from 'react'

function HTMLBlock(props) {
    const block = new Block(props);
    const id = block.getAnchorId();
    const className = 'ob-html-block ' + block.getFieldValue('classNames');
    const text = block.getFieldValue('text');

    return (
        <div
            id={id}
            className={className}
            dangerouslySetInnerHTML={{__html: text}}
        >
        </div>
    )
}

HTMLBlock.propTypes = {

};

export default HTMLBlock;

