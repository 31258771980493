import React from 'react';
import PropTypes from 'prop-types';
import ProductLegacyBlock from '../../adapters/helpers/ProductLegacyBlock';
import './ProductLegacyBody.scss';

export default function ProductLegacyBody(props) {
    const extraAttributes = props.extraAttributes || {};
    const product = extraAttributes.entity;

    const productLegacyBlock = new ProductLegacyBlock({document: props.document, product });

    const children = productLegacyBlock.renderChildBlocks({ extraAttributes }, props.onClickCallback, props.customEvent);

    return (
        <div className={`ob-product-legacy-body ${props.className ? props.className : ''}`}>
            {children}
        </div>
    );
}

ProductLegacyBody.propTypes = {
    className: PropTypes.string,
    extraAttributes: PropTypes.object,
    document: PropTypes.object,
    onClickCallback:  PropTypes.func,
    customEvent: PropTypes.object
};
