import React, { useState, useEffect }  from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import FormInput from '../../../components/FormInput/FormInput'
import FormStore from '../generic-form.store'
import { resetPasswordValidations } from '../form.validators'
import { inputs } from './inputs'
import { SubmitButton } from '../../../components'
import './ResetPasswordForm.scss';
import Eyebrow from '../../../../Eyebrow/Eyebrow';
import ObLink from '../../../../ObLink/ObLink';
import useJanrainGlobalState from '../useJanrainGlobalState';
import {redirectTo} from '../../../helpers/redirect.helpers';
import {focusOnElement} from '../../../../../adapters/helpers/Utils'

const changePasswordStore = new FormStore(resetPasswordValidations, 1);

const ResetPasswordForm = ({ formHeading, formSubHeading, formSubHeadingSuccess, ariaFormIsSubmitting, formSuccessLink, janrainLabels, janrainFormErrors, codeFailRedirectPath}) => {

    const { form, errors, formValidated } = changePasswordStore;

    // Code validation and token state
    const [codeValidated, setCodeValidated] = useState(false);
    const [isInvalidForgotPasswordCode, setIsInvalidForgotPasswordCode] = useState(false);
    const [accessToken, setAccessToken] = useState(undefined);

    // Form state
    const [isFormProcessing, setFormProcessing] = useState(false)
    const [isPasswordChangedSuccessfully, setPasswordChangedSuccessfully] = useState(false)

    const [globalState, setGlobalState] = useJanrainGlobalState();

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setFormProcessing(true);
    };

    useEffect(() => {
        //Verification of code in url query param
        window.janrain.events.onCaptureRenderStart.addHandler(event => verifyCode(event));

        // Form storage
        changePasswordStore.clear();
        changePasswordStore.errorTexts = janrainFormErrors;
        window.janrain.capture.ui.createCaptureSession(accessToken);
    }, [])

    const verifyCode = () => {
        let codeInUrl = false;
        const code = new URLSearchParams(window.location.search).get('code');
        if(code) {
            codeInUrl = true;
        }

        if (!isInvalidForgotPasswordCode && !codeValidated && codeInUrl) {
            window.janrain.capture.ui.renderScreen('resetPasswordRequestCode');
            window.janrain.events.onCaptureSaveFailed.addHandler(handleInvalidForgotPasswordCode);
            window.janrain.events.onCaptureSaveSuccess.addHandler(handleValidForgotPasswordCode);
        } else {
            redirectToForgotPasswordPage();
        }
    }

    const handleInvalidForgotPasswordCode = () => {
        setCodeValidated(true);
        setIsInvalidForgotPasswordCode(true);
        redirectToForgotPasswordPage();
    }

    const handleValidForgotPasswordCode = (event) => {
        if (event.statusMessage === 'forgotPasswordCodeRedeemed') {
            setCodeValidated(true);
            setAccessToken(event.accessToken);
        }
    }

    const redirectToForgotPasswordPage = () => {
        redirectTo(codeFailRedirectPath);
    }

    useEffect(() => {
        if (isFormProcessing) {
            window.janrain.events.onCaptureSaveSuccess.addHandler(handleFormSendSuccess);
            window.janrain.events.onCaptureSaveFailed.addHandler(handleFormSendFailed);
            window.janrain.capture.ui.postCaptureForm(inputs.formName, form);
        }
    }, [isFormProcessing]);

    useEffect(() => {
        if (isPasswordChangedSuccessfully) {
            const successFocus = document.querySelector('.ob-form__subtitle');
            focusOnElement(successFocus);
        }
    }, [isPasswordChangedSuccessfully])

    const handleFormSendFailed = () => {
        setFormProcessing(false)
    }

    const handleFormSendSuccess = () => {
        setFormProcessing(false);
        setPasswordChangedSuccessfully(true);
    }

    return (

        <div className='ob-reset'>
            <div className='ob-form__layout ob-reset__wrapper'>
                <h1 className='ob-form__title' dangerouslySetInnerHTML={{__html: formHeading}}></h1>
                {!isPasswordChangedSuccessfully &&
                <form
                    name={inputs.formName}
                    onSubmit={handlePasswordChange}
                >
                    <h2 className='ob-reset__subtitle ob-form__subtitle' dangerouslySetInnerHTML={{__html: formSubHeading}}></h2>
                    <div className='ob-form__wrapper'>
                        <div className='ob-form__input-wrapper current-password-wrapper'>
                            <FormInput
                                label={janrainLabels.newPasswordFieldLabel}
                                inputType={inputs.type.password}
                                name={inputs.field.newPassword}
                                value={form.newPassword || ''}
                                onChange={(e) => changePasswordStore.fieldChange(inputs.field.newPassword, e.target.value)}
                                validationErrorText={errors.newPassword}
                            />
                        </div>
                        <div className='ob-form__input-wrapper'>
                            <FormInput
                                label={janrainLabels.reEnterPasswordFieldLabel}
                                inputType={inputs.type.password}
                                name={inputs.field.newPasswordConfirm}
                                value={form.newPasswordConfirm || ''}
                                onChange={(e) => changePasswordStore.fieldChange(inputs.field.newPasswordConfirm, e.target.value)}
                                validationErrorText={errors.newPasswordConfirm}
                            />
                        </div>
                    </div>
                    <Eyebrow className='ob-reset__requirements ob-form__requirements'>{janrainLabels.passwordRequirements}</Eyebrow>
                    <SubmitButton
                        buttonText={janrainLabels.submitButtonLabel}
                        disabled={!formValidated}
                        ariaFormIsSubmitting={ariaFormIsSubmitting}
                        isSubmitting={isFormProcessing}
                    />
                </form>}

                {isPasswordChangedSuccessfully &&
                    <div className='ob-form__success'>
                        <h2 className='ob-form__subtitle' dangerouslySetInnerHTML={{__html: formSubHeadingSuccess}}></h2>
                        <ObLink href={formSuccessLink.fields.pagePath.fields.slug}>{formSuccessLink.fields.title}</ObLink>
                    </div>}
            </div>
        </div>
    )
};

ResetPasswordForm.propTypes = {
    janrainFormErrors: PropTypes.object.isRequired,
    formHeading: PropTypes.string,
    formSubHeading: PropTypes.string,
    formSubHeadingSuccess: PropTypes.string,
    ariaFormIsSubmitting: PropTypes.string,
    formSuccessLink: PropTypes.object,
    janrainLabels: PropTypes.shape({
        newPasswordFieldLabel: PropTypes.string.isRequired,
        reEnterPasswordFieldLabel: PropTypes.string.isRequired,
        passwordRequirements: PropTypes.string.isRequired,
        submitButtonLabel: PropTypes.string.isRequired
    }).isRequired,
};

export default observer(ResetPasswordForm)
