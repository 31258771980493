import React, { useCallback, useState, useRef, useMemo } from 'react';
import './CollapsibleProductsOverview.scss';
import PropTypes from 'prop-types';
import ProductOverview from '../ProductOverview';
import { Waypoint } from 'react-waypoint';
import C from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { chunk, minimum} from '../../../adapters/helpers/functions';
import SeeMoreButton from '../../SeeMoreButton/SeeMoreButton';
import {useIsomorphicLayoutEffect} from '../../../adapters/helpers/Hooks';

function makeRows(products, itemsPerLine) {
    return chunk(products, itemsPerLine)
    .map(chunk => {
        const aspectRatios = chunk
        .map((item) =>{ 
            if(item?.fields?.mainAsset?.fields){
                item.fields.mainAsset.fields.isPreload = false
            }
            item?.fields?.mainAsset?.fields?.imageRendition?.fields?.aspectRatio});


        const minAspectRatio = minimum(aspectRatios)

        return {aspectRatio: minAspectRatio, items: chunk};
    }).map(row => {
        // Temporary while aspect Ratios are too extreme
        const aspectRatio = row.aspectRatio && Math.max(row.aspectRatio, 0.4);
        return {...row, aspectRatio};
    });
}

export default function CollapsibleProductsOverview({
    products,
    seeMoreProductsText,
    seeLessProductsText,
    whiteText,
    itemsPerRow,
    onClickCallback,
    productOverview,
    isRecentlyViewed,
    removeLazyLoad
}) {

    const [isViewedMore, setIsViewedMore] = useState(false);
    const [navHeight, setNavHeight] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const collapsibleRef = useRef(null);
    const wrapperRef = useRef(null);
    const [isShown, setIsShown] = useState(false);

    const onViewMore = useCallback(
        () => {
            setIsViewedMore(prevState => !prevState);
        },
        [],
    );

    const handleWaypoint = useCallback(() => {
        setIsShown(true);
    }, []);

    const calculateHeight = useCallback(
        () => {
            if (collapsibleRef.current) {
                setNavHeight(collapsibleRef.current.scrollHeight);
            }
        },
        [],
    );

    useIsomorphicLayoutEffect(() => {
        if (wrapperRef?.current && isLoaded && !isRecentlyViewed) {
            const seeMoreWrapperClassName = 'ob-collapsible-products-overview-see-more-wrapper';
            const collapsibleWrapperClassName = 'ob-collapsible-products-overview-collapsible';
            const imageWrapperSelector = '.ob-products-overview__overview-image-wrapper-1';
            const productsOverviewRows = Array.from(wrapperRef?.current?.children);
            let imageHeight = 0;
            // The timeout is to give time for the images to load
            let timeout = null;
            timeout = window.setTimeout(() => {
                productsOverviewRows.forEach(productsOverviewRow => {
                    if (!productsOverviewRow.classList.contains(seeMoreWrapperClassName)) {
                        const productsOverviewList = Array.from(productsOverviewRow.children);

                        productsOverviewList.forEach((productsOverviewListItem => {
                            if (productsOverviewListItem.classList.contains(collapsibleWrapperClassName)) {
                                const innerProductsOverviewList = Array.from(productsOverviewListItem.children);
                                let innerImageHeight = 0;

                                innerProductsOverviewList.forEach(innerProductsOverviewListItem => {
                                    const imageWrapper = innerProductsOverviewListItem.querySelector(imageWrapperSelector);
                                    const currentImageHeight = imageWrapper.clientHeight;
                                    innerImageHeight = currentImageHeight > innerImageHeight ? currentImageHeight : innerImageHeight;
                                });

                                // we have to do another loop to make sure every item gets the proper height
                                innerProductsOverviewList.forEach(innerProductsOverviewListItem => {
                                    const imageWrapper = innerProductsOverviewListItem.querySelector(imageWrapperSelector);
                                    imageWrapper.style.height = `${innerImageHeight}px`;
                                });
                            }

                            const imageWrapper = productsOverviewListItem.querySelector(imageWrapperSelector);
                            const currentImageHeight = imageWrapper.clientHeight;
                            imageHeight = currentImageHeight > imageHeight ? currentImageHeight : imageHeight;
                        }));

                        // we have to do another loop to make sure every item gets the proper height
                        productsOverviewList.forEach(productsOverviewListItem => {
                            const imageWrapper = productsOverviewListItem.querySelector(imageWrapperSelector);
                            imageWrapper.style.height = `${imageHeight}px`;
                        });
                    }
                });
            }, 500);

            return () => {
                window.clearTimeout(timeout)
            }
        }
    },[isLoaded, isViewedMore]);

    const fullScreenWidth = useMemo(() => `${(100 / itemsPerRow).toFixed(2)}%`, [itemsPerRow]);
    const rows = useMemo(() => makeRows(products, itemsPerRow), [products, itemsPerRow]);
    const firstRow = useMemo(() => rows.length > 0 && rows[0], [rows]);
    const remainingRows = useMemo(() => rows.length > 1 ? rows.slice(1) : undefined, [rows]);

    const Row = useCallback(({row, isShown, isAnimated = false}) => (
            <>
                {row.items.map((item, i) => (
                    <div key={i} style={{width: fullScreenWidth}}
                        className="ob-collapsible-products-overview-product-wrapper">
                            <ProductOverview product={item}
                                whiteText={whiteText}
                                aspectRatio={row.aspectRatio}
                                index={i}
                                isShown={isShown}
                                isRecentlyViewed={isRecentlyViewed}
                                isAnimated={isAnimated}
                                removeLazyLoad={removeLazyLoad}
                                onClickCallback={onClickCallback}
                                productOverview={productOverview}/>
                    </div>
                ))}
            </>
        ), [fullScreenWidth, whiteText]);

    return (
        <CSSTransition in={isViewedMore} timeout={800} onEntering={calculateHeight} onExit={calculateHeight}>
            <div ref={wrapperRef} className={C('ob-collapsible-products-overview')} onLoad={() => setIsLoaded(true)}>

                {/* Arbitrary numbers */}
                {!!firstRow && <Waypoint onEnter={handleWaypoint} topOffset={'500px'} bottomOffset={'300px'}>
                    <div className="ob-collapsible-products-overview-first-line">
                        <Row row={firstRow} isShown={isShown} isAnimated />
                    </div>
                </Waypoint>}

                {!!remainingRows &&
                <div className="ob-collapsible-products-overview-collapsible-wrapper" style={{ height: navHeight }}>
                    <div className="ob-collapsible-products-overview-collapsible" ref={collapsibleRef}>
                        {remainingRows.map((row, i) => (
                            <Row key={i}
                                isShown
                                row={row}
                                isAnimated />
                        ))}
                    </div>
                </div>}
                {!!remainingRows && !!seeMoreProductsText &&
                <div className="ob-collapsible-products-overview-see-more-wrapper">
                    <SeeMoreButton whiteText={whiteText}
                        onClick={onViewMore}
                        isOpen={isViewedMore}
                        seeMoreText={seeMoreProductsText}
                        seeLessText={seeLessProductsText}/>
                </div>}
            </div>
        </CSSTransition>
    )
}

CollapsibleProductsOverview.defaultProps = {
    products: [],
}

CollapsibleProductsOverview.propTypes = {
    products: PropTypes.array,
    seeMoreProductsText: PropTypes.string,
    seeLessProductsText: PropTypes.string,
    whiteText: PropTypes.bool,
    itemsPerRow: PropTypes.number.isRequired,
    productOverview: PropTypes.any,
    removeLazyLoad: PropTypes.bool,
    onClickCallback: PropTypes.func,
    isRecentlyViewed: PropTypes.bool
}
