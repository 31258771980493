import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormInput from '../../../components/FormInput/FormInput'
import FormStore from '../generic-form.store'
import ObLink from '../../../../ObLink/ObLink';
import Eyebrow from '../../../../Eyebrow/Eyebrow';

import {focusOnElement} from '../../../../../adapters/helpers/Utils';
import { changePasswordValidations } from '../form.validators';
import { inputs } from './inputs';
import { SubmitButton } from '../../../components';
import { observer } from 'mobx-react';

import './ChangePasswordForm.scss';

const changePasswordStore = new FormStore(changePasswordValidations, 2);

const ChangePasswordForm = (
    { formHeading, 
        formSuccessLink, 
        formSubHeadingSuccess, 
        ariaFormIsSubmitting, 
        janrainLabels, 
        janrainFormErrors }) => {

    const { form, errors, formValidated, errorsNumber } = changePasswordStore

    const [isFormProcessing, setFormProcessing] = useState(false)
    const [isPasswordChangedSuccessfully, setPasswordChangedSuccessfully] = useState(false)

    const handlePasswordChange = (e) => {
        e.preventDefault()
        setFormProcessing(true)
    };

    useEffect(() => {
        changePasswordStore.clear();
        changePasswordStore.errorTexts = janrainFormErrors
    }, []);

    useEffect(() => {
        if (isFormProcessing) {
            window.janrain.events.onCaptureSaveSuccess.addHandler(handleFormSendSuccess)
            window.janrain.events.onCaptureSaveFailed.addHandler(handleFormSendFailed)
            window.janrain.capture.ui.postCaptureForm(inputs.formName, form)
        }
    }, [isFormProcessing]);

    useEffect(() => {
        if (isPasswordChangedSuccessfully) {
            const successFocus = document.querySelector('.ob-form__subtitle');
            focusOnElement(successFocus);
        }
    }, [isPasswordChangedSuccessfully]);

    const handleFormSendFailed = () => {
        setFormProcessing(false)
    };

    const handleFormSendSuccess = () => {
        setFormProcessing(false);
        setPasswordChangedSuccessfully(true);
    };

    return (
        <div className={`ob-change ${isPasswordChangedSuccessfully ? 'updated' : ''}`}>
            <div className='ob-form__layout ob-change_wrapper'>
                <h1 className='ob-form__title' dangerouslySetInnerHTML={{ __html: formHeading }}></h1>
                {!isPasswordChangedSuccessfully &&
                    <form
                        name={inputs.formName}
                        onSubmit={handlePasswordChange}
                    >

                        {/* {errorsNumber > 0 &&
                            Global error state numbers
                            <p className='ob-form__global-error'>{errorsNumber} error{errorsNumber > 1 ? 's' : ''} found.</p>
                        } */}

                        {/*<div className='ob-form__wrapper'>*/}
                        {/*    <div className='ob-form__input-wrapper'>*/}
                        {/*        <FormInput*/}
                        {/*            label={janrainLabels.currentPasswordFieldLabel}*/}
                        {/*            inputType={inputs.type.password}*/}
                        {/*            name={inputs.field.currentPassword}*/}
                        {/*            value={form.currentPassword || ''}*/}
                        {/*            onChange={(e) => changePasswordStore.fieldChange(inputs.field.currentPassword, e.target.value)}*/}
                        {/*            validationErrorText={errors.currentPassword}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className='ob-form__wrapper'>
                            <div className='ob-form__input-wrapper'>
                                <FormInput
                                    label={janrainLabels.changePasswordNewPasswordFieldLabel}
                                    inputType={inputs.type.password}
                                    name={inputs.field.newPassword}
                                    value={form.newPassword ? form.newPassword : ''}
                                    ariaDescribedBy='inputDescription'
                                    onChange={(e) => changePasswordStore.fieldChange(inputs.field.newPassword, e.target.value)}
                                    validationErrorText={errors.newPassword}
                                />
                            </div>
                            <div className='ob-form__input-wrapper'>
                                <FormInput
                                    label={janrainLabels.changePasswordConfirmNewPasswordFieldLabel}
                                    inputType={inputs.type.password}
                                    name={inputs.field.newPasswordConfirm}
                                    value={form.newPasswordConfirm ? form.newPasswordConfirm : ''}
                                    ariaDescribedBy='inputDescription'
                                    onChange={(e) => changePasswordStore.fieldChange(inputs.field.newPasswordConfirm, e.target.value)}
                                    validationErrorText={errors.newPasswordConfirm}
                                />
                            </div>
                        </div>
                        <Eyebrow id='inputDescription' className='ob-change__requirements ob-form__requirements'>{janrainLabels.passwordRequirements}</Eyebrow>
                        <SubmitButton
                            buttonText={janrainLabels.submitButtonLabel}
                            disabled={!formValidated}
                            ariaFormIsSubmitting={ariaFormIsSubmitting}
                            isSubmitting={isFormProcessing}
                        />
                    </form>}

                {isPasswordChangedSuccessfully &&
                    <div className='ob-form__success'>
                        <h2 className='ob-form__subtitle' dangerouslySetInnerHTML={{ __html: formSubHeadingSuccess }}></h2>
                        <ObLink href={formSuccessLink.fields.pagePath.fields.slug}>{formSuccessLink.fields.title}</ObLink>
                    </div>
                }
            </div>
        </div>
    )
};

ChangePasswordForm.propTypes = {
    formHeading: PropTypes.string,
    formSubHeadingSuccess: PropTypes.string,
    ariaFormIsSubmitting: PropTypes.string,
    formSuccessLink: PropTypes.object,
    janrainLabels: PropTypes.shape({
        currentPasswordFieldLabel: PropTypes.string.isRequired,
        changePasswordNewPasswordFieldLabel: PropTypes.string.isRequired,
        changePasswordConfirmNewPasswordFieldLabel: PropTypes.string.isRequired,
        passwordRequirements: PropTypes.string.isRequired,
        submitButtonLabel: PropTypes.string.isRequired,
    }).isRequired,
    janrainFormErrors: PropTypes.object.isRequired
};

export default observer(ChangePasswordForm)
