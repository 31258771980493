import {SpotlightExperienceProductConstants} from '../../adapters/helpers/Constants';
import C from 'classnames';
import {getColorStyle, stripHtml} from '../../adapters/helpers/Utils';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

export default function SpotlightExperienceProduct(props) {
    const {doc, product, itemIndex, isSelected, onProductChange, onModalChange, onImageChange, onCtaChange} = props;

    const [selectedVariantIndex, setSelectedVariant] = useState(0);
    const [hasLoaded, setHasLoaded] = useState(false);
    const productOptions = product.fields.experienceFeatures;
    const productVariants = product.fields.productVariants;
    const productVariant = productVariants[selectedVariantIndex];
    const productImage = productVariant?.fields?.mainAsset || product.fields.mainAsset;
    const handleColorLabel = doc.fields?.handleColorLabel?.fields?.text;
    const descriptionLabel = doc.fields?.descriptionLabel?.fields?.text;
    const title = product?.fields?.experienceTitle || product.fields.title;
    const modalContent = product?.fields?.experienceTooltip;
    const modalTooltipAriaLabel = product?.fields?.tooltipAriaLabel?.fields?.text;
    const productCtaLabel =  product.fields?.buyNowLabel?.fields?.text;
    const productSku = productVariant?.fields?.sku;

    const updateProduct = () => {
        onProductChange(itemIndex);
    };

    const updateImage = () => {
        // Prevent wrong image to show on module load as all product set their images
        if(isSelected || hasLoaded) {
            onImageChange(productImage);
            onCtaChange(productSku, productCtaLabel);
        }

        if(!hasLoaded) {
            setHasLoaded(true);
        }
    };

    const selectProduct = () => {
        updateImage();
        updateProduct();
    };

    const updateColor = (index) => {
        setSelectedVariant(index);

        if(!isSelected) {
            updateProduct();

            if(selectedVariantIndex === index) {
                updateImage();
            }
        }
    };

    const updateModalContent = () => {
        onModalChange(modalContent);
    };

    useEffect(() => {
        updateImage();
    }, [selectedVariantIndex]);

    useEffect(() => {
        if(isSelected) {
            selectProduct();
        }
    }, []);

    return (
        <div className="ob-spotlight-experience__product">
            <div className="ob-spotlight-experience__product-info">
                <h3 className="ob-spotlight-experience__product-name">
                    <button className="ob-spotlight-experience__product-name__btn event_button_click"
                            onClick={selectProduct}
                            data-action-detail={stripHtml(title)}
                            dangerouslySetInnerHTML={{__html: title}}>
                    </button>
                </h3>

                {(productOptions && productOptions.length > 0) && (
                    <div className="ob-spotlight-experience__product-options-text">
                        {descriptionLabel &&
                            <h4 className="visuallyhidden">{descriptionLabel}</h4>
                        }

                        <ul>
                            {productOptions.map((item, index) => (
                                <li key={index}>
                                    {item}
                                    {index === productOptions.length - 1 &&
                                        <button className="ob-spotlight-experience__modal-bubble event_button_click"
                                                data-action-detail={modalTooltipAriaLabel}
                                                aria-label={modalTooltipAriaLabel}
                                                onClick={updateModalContent}>i</button>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            {(productVariants && productVariants.length > 0) && (
                <div className="ob-spotlight-experience__product-colors">
                    {handleColorLabel &&
                        <h4 className="visuallyhidden">{handleColorLabel}</h4>
                    }

                    <ul className="ob-series-comparison__product-colors-list">
                        {productVariants.map((variant, i) => {
                            const color = variant?.fields?.color;
                            if (color) {
                                return (
                                    <li className="ob-series-comparison__product-colors-item" key={i}>
                                        <button
                                            type={SpotlightExperienceProductConstants.button}
                                            className={C('ob-series__color ob-series__color--large-mobile event_button_click', {
                                                ['ob-series__color--active']: i === selectedVariantIndex
                                            })}
                                            style={getColorStyle(color)}
                                            data-action-detail={color.fields.name}
                                            onClick={() => updateColor(i)}
                                            title={color.fields.name}
                                            aria-pressed={i === selectedVariantIndex ? true : false}
                                        >
                                            {color.fields.name}
                                        </button>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
}

SpotlightExperienceProduct.propTypes = {
    doc: PropTypes.object,
    itemIndex: PropTypes.any,
    product: PropTypes.any,
    isSelected: PropTypes.any,
    onProductChange: PropTypes.func,
    onModalChange: PropTypes.func,
    onImageChange: PropTypes.func,
    onCtaChange: PropTypes.func,
    onClickCallback: PropTypes.func,
};
