import React from 'react';
import PropTypes from 'prop-types';
import ArticleBlock from '../../adapters/helpers/ArticleBlock';
import './ArticleBody.scss';

export default function ArticleBody(props) {
    const extraAttributes = props.extraAttributes || {};
    const article = extraAttributes.entity;
    const classAttribute = extraAttributes.entity?.className;

    const articleBlock = new ArticleBlock({document: props.document, article });

    const children = articleBlock.renderChildBlocks({ extraAttributes }, props.onClickCallback, props.customEvent);

    return (
        <div className={`ob-article-body ${classAttribute}`}>
            {children}
        </div>
    );
}

ArticleBody.propTypes = {
    className: PropTypes.string,
    extraAttributes: PropTypes.object,
    document: PropTypes.object,
    onClickCallback:  PropTypes.func,
    customEvent: PropTypes.object
};
