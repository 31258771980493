import React from 'react'
import PropTypes from 'prop-types'
import './Disclaimer.scss'
import {createHtmlFriendlyId} from '../../adapters/helpers/Utils';

function Disclaimer({children, id, className, styles, whiteText, document}) {

    const disclaimer = children ? children : document?.fields?.disclaimerText;
    let anchorId = '';

    if (id) {
        anchorId = id;
    } else if (document?.fields?.name) {
        anchorId = createHtmlFriendlyId(document?.fields?.name);
    } else {
        anchorId = '';
    }

    if (!disclaimer) {
        return null
    }

    if (className) {
        className = `ob-disclaimer ${className}`
    } else if (document?.fields?.className) {
        className = `ob-disclaimer ${document?.fields?.className}`
    } else {
        className = 'ob-disclaimer'
    }

    return (
        <div
        id={anchorId}
            className={className}
            data-white-text={whiteText ? whiteText : className?.includes('white-text')}
            style={styles}
            dangerouslySetInnerHTML={{__html: disclaimer}}
        >
        </div>
    )
}

Disclaimer.propTypes = {
    /**
    * The html to be structured
    */
    children: PropTypes.node,

    /**
    * The tag to be used for the containing element
    */
    tag: PropTypes.string,

    /**
    * The ID attribute be added to the element
    */
    id: PropTypes.string,

    /**
    * The Class attribute be added to the element
    */
    className: PropTypes.string,

    /**
     * Add custom styles to the disclaimer
     */
    styles: PropTypes.object,

    /**
    * Set white text if needed
    */
    whiteText: PropTypes.bool,

    document: PropTypes.object
}

Disclaimer.defaultProps = {
    tag: 'p',
    className: '',
    styles: {},
    whiteText: false
  }

export default Disclaimer

