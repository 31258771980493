import React from 'react'
import PropTypes from 'prop-types'
import './ProgressBar.scss'
import usePercentage from '../../adapters/helpers/usePercentage';

export default function ProgressBar(props) {

    const percentage = usePercentage((props.currentIndex) , props.componentsTotalLength - 1);

    return (
        <div className='ob-ProgressBar'>
            <div className='ob-ProgressBar-inner' style={{ width: percentage ? percentage : 0 }}></div>
        </div>
    )
}

ProgressBar.propTypes = {
    currentIndex: PropTypes.number,
    componentsTotalLength: PropTypes.number
}