import './ProductOverview.scss';
import { stripHtml } from '../../adapters/helpers/Utils';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import {ProductOverviewConstant} from '../../adapters/helpers/Constants';
import React from 'react';
import C from 'classnames';
import PropTypes from 'prop-types';
import Image from '../Image/Image';
import Button from '../Button/Button';

function ProductOverviewLoaded({
    whiteText,
    aspectRatio,
    title,
    shortTitle,
    slug,
    index,
    buyNowText,
    image,
    isAnimated,
    isShown,
    isAutoHeightMode,
    isFullSizeImage,
    isMedium,
    sku,
    onClickCallback,
    isRecentlyViewed,
    removeLazyLoad,
    comingSoonText,
    comingSoonLink,
    buyNowDeactivated
}) {

    const imageDelay = index * 0.25;
    const contentDelay = imageDelay + 0.25;
    const altText = image?.fields?.alternateText ? image.fields.alternateText : stripHtml(title);

    return (
        <div className={C('ob-products-overview__overview',
            {'ob-products-overview__overview--white-text': whiteText},
            {'ob-products-overview__overview--animated': isAnimated},
            {'ob-products-overview__overview--shown': isShown},
            {'ob-products-overview__overview--auto-height-mode': isAutoHeightMode},
            {'ob-products-overview__overview--is-recently-viewed': isRecentlyViewed},
            {'ob-products-overview__overview--full-size-image': isFullSizeImage})}>
            {!!aspectRatio && <div className='ob-products-overview__overview-image-wrapper-1'>
                <div className='ob-products-overview__overview-image-wrapper-2'>
                    <div className='ob-products-overview__overview-image-wrapper-3'>
                        <div className='ob-products-overview__overview-image-wrapper-4' style={{paddingBottom: isRecentlyViewed || isMedium ? `${100 / aspectRatio}%` : null}}>
                            <div className='ob-products-overview__overview-image' style={{animationDelay: imageDelay + 's'}}>
                                <a href={slug}
                                   aria-label={`${altText} ${ProductOverviewConstant.link}`}
                                   className='event_internal_link'
                                   data-action-detail={stripHtml(title)}>
                                    {!!image && <Image noLazyLoad={removeLazyLoad} altText={altText} image={image}/>}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            <div className='ob-products-overview__overview-content-wrapper-1'>
                <div className='ob-products-overview__overview-content-wrapper-2'>
                    <div className='ob-products-overview__overview-content'  style={{animationDelay: contentDelay + 's'}}>
                        <div className='ob-products-overview__overview-lower-content-wrapper'>
                            <div className='ob-products-overview__overview-lower-content'>
                                {!!title && <a href={slug}
                                    className='event_internal_link ob-products-overview__overview-link'
                                    data-action-detail={stripHtml(title)}
                                    dangerouslySetInnerHTML={{__html: shortTitle ? shortTitle : title}}/>}
                                {!!buyNowText && !buyNowDeactivated &&
                                    <Button
                                        className='event_buy_now'
                                        dataActionDetail={stripHtml(title)}
                                        invertedTheme={!!whiteText}
                                        greyTheme={true}
                                        onClick={event => onClickCallback(event)}
                                        sku={sku}
                                    >
                                        {buyNowText}
                                    </Button>
                                }
                                {buyNowDeactivated &&
                                    <Button
                                        tag={'a'}
                                        disabled={comingSoonLink === ''}
                                        href={comingSoonLink}
                                        target={'_blank'}
                                    >
                                        {comingSoonText}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductOverviewLoaded.defaultProps = {
    isFullSizeImage: false,
}

ProductOverviewLoaded.propTypes = {
    whiteText: PropTypes.bool,
    aspectRatio: PropTypes.number,
    title: PropTypes.string,
    shortTitle: PropTypes.string,
    slug: PropTypes.string,
    buyNowText: PropTypes.string,
    image: PropTypes.object,
    index: PropTypes.number.isRequired,
    isAnimated: PropTypes.bool,
    isShown: PropTypes.bool,
    isFullSizeImage: PropTypes.bool,
    isAutoHeightMode: PropTypes.bool,
    isRecentlyViewed: PropTypes.bool,
    sku: PropTypes.string,
    onClickCallback: PropTypes.func.isRequired,
    productOverview: PropTypes.any,
    isMedium: PropTypes.bool,
    removeLazyLoad: PropTypes.bool,
    buyNowDeactivated: PropTypes.bool,
    comingSoonText: PropTypes.string,
    comingSoonLink: PropTypes.string,
}

function ProductOverviewLoading({aspectRatio}) {
    return (
        <div className='ob-products-overview__overview ob-products-overview__overview--loading'>
            {!!aspectRatio && <div className='ob-products-overview__overview-image-wrapper-1'>
                <div className='ob-products-overview__overview-image-wrapper-2'>
                    <div className='ob-products-overview__overview-image-wrapper-3'>
                        <div className='ob-products-overview__overview-image-wrapper-4' style={{paddingBottom: `${100 / aspectRatio}%`}}>
                            <div className='ob-products-overview__overview-image-placeholder' ></div>
                        </div>
                    </div>
                </div>
            </div>}

            <div className='ob-products-overview__overview-content-wrapper-1'>
                <div className='ob-products-overview__overview-content-wrapper-2'>
                    <div className='ob-products-overview__overview-content' style={{opacity: 1}}>
                        <div className='ob-products-overview__overview-upper-content-wrapper'>
                            <div className='ob-products-overview__overview-upper-content'>
                                <span className='ob-products-overview__overview-upper-content-placeholder' />
                            </div>
                        </div>
                        <div className='ob-products-overview__overview-lower-content-wrapper'>
                            <div className='ob-products-overview__overview-lower-content'>
                                <div className='ob-products-overview__overview-link'>
                                    <span className='ob-products-overview__overview-link-placeholder'></span>
                                </div>
                                <span className='ob-products-overview__overview-button-placeholder'></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductOverviewLoading.propTypes = {
    aspectRatio: PropTypes.number,
}

export default function ProductOverview({
    product,
    whiteText,
    aspectRatio,
    index,
    isAnimated,
    isShown,
    isAutoHeightMode,
    onClickCallback,
    productOverview,
    isRecentlyViewed,
    isMedium,
    removeLazyLoad
}) {
    if(product) {
        const slug = product?.fields?.slug?.fields?.slug || productOverview?.fields?.slug?.fields?.slug;
        const title  = product?.fields?.title || productOverview?.fields?.title;
        const shortTitle  = product?.fields?.shortTitle || productOverview?.fields?.shortTitle;
        const hasProductVariants = (product?.fields?.productVariants && product?.fields?.productVariants.length > 0);
        const image = (hasProductVariants && product?.fields?.productVariants[0]?.fields?.productGridAsset) ?
            product?.fields?.productVariants[0]?.fields?.productGridAsset : product?.fields?.mainAsset;
        const buyNowText = product?.fields?.buyNowLabel?.fields?.text || productOverview?.fields?.buyNowLabel?.fields?.text;
        const isFullSizeImage = product?.fields?.isFullSizeImage;
        const sku = product?.fields?.productVariants?.length > 0 ? product?.fields?.productVariants[0]?.fields?.sku :
            product?.fields?.sku ? product?.fields?.sku : '';
        let comingSoonText = productOverview?.fields?.comingSoonLabel?.fields?.text || product?.fields?.comingSoonLabel?.fields?.text || '';
        let comingSoonLink = productOverview?.fields?.comingSoonLink || product?.fields?.comingSoonLink || '';
        let buyNowDeactivated = productOverview?.fields?.deactivateBuyNowButton || product?.fields?.deactivateBuyNowButton || false;


        return (<ProductOverviewLoaded
            whiteText={whiteText}
            aspectRatio={aspectRatio}
            slug={slug}
            title={title}
            shortTitle={shortTitle}
            image={image}
            index={index}
            isShown={isShown}
            isAnimated={isAnimated}
            isAutoHeightMode={isAutoHeightMode}
            isMedium={isMedium}
            buyNowText={buyNowText}
            sku={sku}
            onClickCallback={onClickCallback}
            isRecentlyViewed={isRecentlyViewed}
            removeLazyLoad={removeLazyLoad}
            isFullSizeImage={isFullSizeImage}
            comingSoonText={comingSoonText}
            comingSoonLink={comingSoonLink}
            buyNowDeactivated={buyNowDeactivated}/>);

    } else {
        return (<ProductOverviewLoading
            whiteText={whiteText}
            aspectRatio={aspectRatio}/>);
    }
}

ProductOverview.propTypes = {
    product: PropTypes.any,
    whiteText: PropTypes.any,
    aspectRatio: PropTypes.any,
    isAnimated: PropTypes.bool,
    isShown: PropTypes.bool,
    isRecentlyViewed: PropTypes.bool,
    sku: PropTypes.string,
    onClickCallback: PropTypes.func.isRequired,
    productOverview: PropTypes.any,
    isMedium: PropTypes.bool
}

ProductOverview.defaultProps = {
    aspectRatio: 1,
}
