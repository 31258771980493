import React, { useContext, useState, useEffect, useRef } from 'react';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import {SpotlightContestConstants} from '../../adapters/helpers/Constants';
import LayoutContext from '../Layout/LayoutContext';
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {SpotlightWaitlistConstants} from '../../adapters/helpers/Constants';
import './SpotlightContestBlock.scss';
import {escapeHtml} from '../../adapters/helpers/functions';

import {
    registrationUrl,
    registrationHeader,
    formatEURegistrationBody,
    formatUSRegistrationBody,
    surveyUrl,
    submitionSurveyUrl,
    surveyHeader,
    formatSurveyBody,
    campaignUrl,
    campaignHeader,
    getFormattedLocale,
    sendMessageUrl,
    sendHeader,
    formatConstestMessageBody
} from '../../adapters/model/service/gcs/gcsService';

import { checkPattern } from '../../adapters/helpers/Utils';

import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import Label from '../Label/Label';
import ObLink from '../ObLink/ObLink';

function SpotlightContest(props) {
    const { document: entity, isMedium } = props;

    const block = new Block(entity);

    const layoutContext = useContext(LayoutContext);
    const { onContestScrollDown } = layoutContext;

    const emailExceedLimitLabel = block.getFieldValue(SpotlightContestConstants.emailLimitExceedLabel)?.fields?.text;
    const [erroneousInputs, setErroneousInputs] = useState([]);
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [optInId, setOptInId] = useState(false);
    const [surveyId, setSurveyId] = useState(false);
    const [ownETBQuestionId, setOwnETBQuestionId] = useState(false);
    const [sourceQuestionId, setSourceQuestionId] = useState(false);
    const [motivationQuestionId, setMotivationQuestionId] = useState(false);
    const [isInputBlur, setisInputBlur] = useState(false);
    const [countryList, setCountryList] = useState({});

    const title = props?.document?.fields?.title;
    const titleImage = props?.document?.fields?.titleImage;
    const backgroundAsset = props?.document?.fields?.backgroundAsset;
    const mobileBackgroundAsset = props?.document?.fields?.mobileBackgroundAsset;
    const bannerAssetMobile = props?.document?.fields?.bannerAssetMobile;
    const arrowTitle = props?.document?.fields?.arrowTitle;
    const bannerAsset = props?.document?.fields?.bannerAsset;
    const subtitle = props?.document?.fields?.subtitle;
    const contestTitle = block.getFieldValue(SpotlightContestConstants.contestTitle);
    const formFields = block.getFieldValue(SpotlightContestConstants.formFields);

    const oralBLogoAsset = block.getFieldValue(SpotlightContestConstants.oralBLogoAsset);
    const ioLogoAsset = block.getFieldValue(SpotlightContestConstants.ioLogoAsset);
    const formOptinInputText = block.getFieldValue(SpotlightContestConstants.formOptinInputText);
    const formOwnETBInputText = block.getFieldValue(SpotlightContestConstants.formOwnETBInputText);
    const formLegalText = block.getFieldValue(SpotlightContestConstants.formLegalText);
    const signupCallToActionLabel = block.getFieldValue(SpotlightContestConstants.signUpCallToActionLabel);
    const redirectDestination = block.getFieldValue(SpotlightContestConstants.redirectDestination)?.fields?.slug;
    const numberOfErrorsLabel = block.getFieldValue(SpotlightContestConstants.numberOfErrorsLabel);
    const modalLabelFirst = block.getFieldValue(SpotlightContestConstants.modalLabelFirst);
    const modalLabelSecond = block.getFieldValue(SpotlightContestConstants.modalLabelSecond);

    const blockRef = useRef(null);
    const firstLegalModalRef = useRef(null);
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const closeModalLabel = block.getFieldValue(SpotlightWaitlistConstants.closeModalLabel)?.fields?.text;
    const hierarchy = props?.document?.fields?.hierarchy;

    const PAGE_SCROLL_DELTA_THRESHOLD = 10;
    let startTouchPoint = null;
    let endTouchPoint = null;

    let canScrollToNextSection = false;
    let scrollDownDebounceTimeout = null;

    const customStylesLegal = {
        overlay: {
            backgroundColor: 'none'
        },
        content : {
            position            : 'fixed',
            border              : 'none',
            top                 : '0',
            left                : '0',
            right               : '0',
            bottom              : '0',
            padding             : '0',
            marginRight         : '0',
            height              : '100%',
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(255, 255, 255, 0.5)'
        }
    };

    useEffect(() => {
        // define optin id for the current country
        setOptinId();

        // assign servey question ids
        if(surveyUrl) {
            setQuestionsIds();
        }

        setTouchListeners();

        return function cleanup() {
            unsetTouchListeners();
        }
    },[]);

    useEffect(() => {
        const errors = document.querySelector('.ob-focus-error');
        if (!errors) {
            return;
        }

        if (!isInputBlur) {
            errors.focus();
        }
    },[erroneousInputs, serverSideErrorMessage]);

    useEffect(() => {
        if (document.querySelector('.js-modal-container')) {
            Modal.setAppElement('.js-modal-container');
        }
    },[]);

    useEffect(
        () => {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
            if (firstModalId) {
                firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let firstModalMobileId = blockRef.current.querySelector('#openFirstLegalModalMobile');
            if (firstModalMobileId) {
                firstModalMobileId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let secondModalId = blockRef.current.querySelector('#openSecondLegalModal');
            if (secondModalId) {
                secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }

            let secondModalMobileId = blockRef.current.querySelector('#openSecondLegalModalMobile');
            if (secondModalMobileId) {
                secondModalMobileId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        },[blockRef.current]
    );

    const setTouchListeners = () => {
        blockRef.current.addEventListener('touchstart', touchStart, {passive: true});
        blockRef.current.addEventListener('touchend', touchEnd, {passive: true});
        blockRef.current.addEventListener('touchmove', touchMove, {passive: true});
    };

    const unsetTouchListeners = () => {
        blockRef.current.removeEventListener('touchstart', touchStart, {passive: true});
        blockRef.current.removeEventListener('touchend', touchEnd, {passive: true});
        blockRef.current.removeEventListener('touchmove', touchMove, {passive: true});
    };

    const closeFirstLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);
    };

    const setOptinId = function() {
        let optInId = '';
        fetch(campaignUrl, {
            method: 'GET',
            headers: campaignHeader,
        }).then((response) => {
            if (response.status === 200) {
                response.json().then(json => {
                    let optIns = json.optIns[0];
                    // set optIn id that will be use in the form
                    if (optIns) {
                        setOptInId(optIns.optInId);
                    }

                    let filteredCountryListResult = [];
                    let campaignCountryList = json.campaignCountriesForAddress;
                    // compare countries from the campaign with the one in contentful
                    // only add the countries present in the campgain in the list for the dropdown
                    for (let y = 0; y < campaignCountryList.length; ++y) {
                        const campaignCountryId = campaignCountryList[y]?.countryId;
                        const campaignCountryName = campaignCountryList[y]?.countryName;
                        const isoCode = campaignCountryList[y]?.isoCode.length > 2 ? campaignCountryList[y]?.isoCode : campaignCountryList[y]?.countryCode;
                        const countryObject = {
                            countryName: campaignCountryName,
                            countryIsoCode: isoCode,
                            countryId: campaignCountryId,
                        };

                        filteredCountryListResult.push(countryObject);
                    }
                    // set countryList for dropdown
                    setCountryList(filteredCountryListResult);
                });
            }
        });

        return optInId;
    };

    const setQuestionsIds = () => {
        fetch(surveyUrl, {
            method: 'GET',
            headers: surveyHeader,
        }).then(async(surveyResponse) => {
            return surveyResponse.json();
        }).then((surveys) => {
            surveys.forEach(survey => {

                setSurveyId(survey.surveyId);

                survey?.questions.forEach(question => {
                    const questionName = question?.questionName;
                    if(questionName && questionName.toLowerCase().indexOf('electric') > -1) {
                        setOwnETBQuestionId(question.questionId);
                    } else if(questionName && questionName.toLowerCase().indexOf('source') > -1) {
                        setSourceQuestionId(question.questionId);
                    } else if(questionName && questionName.toLowerCase().indexOf('motivation') > -1) {
                        setMotivationQuestionId(question.questionId);
                    }
                })
            })
        });
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        setisInputBlur(false);
        setIsSubmitting(true);
        setErroneousInputs([]);

        const form = event.target;
        const firstname = form.firstname?.value;
        const email = form.email?.value;
        const optinCheckboxIsChecked = document.querySelector('#OptinCheckbox') != null && document.querySelector('#OptinCheckbox').checked;
        const ownETBCheckboxIsChecked = document.querySelector('#OwnETBCheckbox') != null && document.querySelector('#OwnETBCheckbox').checked;
        const motivationInput = document.querySelector('#short-motivation') != null ? escapeHtml(document.querySelector('#short-motivation').value) : '';
        const country = getCountryByName(form['nordic-select-country']?.value);

        const inputs = form.querySelectorAll('input, select');
        let errors = validateFormInputs(inputs);

        if (errors.length === 0) {
            await submitRegistration(firstname, email, optinCheckboxIsChecked, ownETBCheckboxIsChecked, motivationInput, country);
        } else {
           setErroneousInputs([...errors]);
           setIsSubmitting(false);
        }
    };

    const submitRegistration = async(firstname, email, optInIsChecked, hasAnElectricToothbrush, motivationInput, country) => {
        const formattedLocal = getFormattedLocale();
        const requestBody = (formattedLocal == 'en-CA' || formattedLocal == 'en-CA')
            ? formatUSRegistrationBody(firstname, email, formattedLocal, optInId)
            : formatEURegistrationBody(firstname,
                    email,
                    formattedLocal,
                    null,
                    null,
                    country.countryIsoCode,
                    optInId,
                    optInIsChecked);

        fetch(registrationUrl, {
            method: 'POST',
            headers: registrationHeader,
            body: JSON.stringify(requestBody)
        }).then(async(response) => {
            // 201 for created
            if (response.status === 201 || response.status === 200) {
                // convert response
                const registrationResponse = await response.json();

                // save in memory the order id
                const orderId = registrationResponse?.orderId;

                await postSurveyInformations(orderId, hasAnElectricToothbrush, firstname, email, motivationInput);
            } else {
                return response.json();
            }
        }).then(object => {
            manageServerSideErrors(object);
        }).catch((e) => {
            console.error(e);
            setServerSideErrorMessage(SpotlightWaitlistConstants.error + SpotlightWaitlistConstants.failedToRegister);
            setIsSubmitting(false);
        });
    };

    const postSurveyInformations = async(orderId, hasElectricToothbrush, firstname, email, motivationInput) => {
        const locale = getFormattedLocale();
        const requestBody = formatSurveyBody(locale,
            surveyId,
            orderId,
            ownETBQuestionId,
            hasElectricToothbrush,
            sourceQuestionId,
            motivationQuestionId,
            motivationInput);
            
        if(submitionSurveyUrl) {
            fetch(submitionSurveyUrl, {
                method: 'POST',
                headers: surveyHeader,
                body: JSON.stringify(requestBody)
            }).then(async (response) => {

                if (response.status === 201 || response.status === 200) {
                    const mailHasBeenSent = sendMessageUrl ? await sendMailConfirmation(firstname, email, locale) : '';

                    if(mailHasBeenSent) {
                        if (typeof (window.dataLayer) !== 'undefined') {
                            window.dataLayer.push({
                                'event': 'customEvent',
                                'GAeventCategory': 'event_crm_action',
                                'GAeventAction': 'event_profile_email_email_signup'
                            });
                        }

                        window.location.href = redirectDestination;
                    } else {
                        setServerSideErrorMessage(SpotlightWaitlistConstants.error + SpotlightWaitlistConstants.failedToRegister);
                        setIsSubmitting(false);
                    }

                } else {
                    return response.json();
                }
            }).then(object => {
                manageServerSideErrors(object);
            }).catch((e) => {
                console.error(e);
                setServerSideErrorMessage(SpotlightWaitlistConstants.error + SpotlightWaitlistConstants.failedToRegister);
                setIsSubmitting(false);
            });
        }
    };

    const sendMailConfirmation = async (firstname, email, locale) => {
        const messageRequestBody = formatConstestMessageBody(firstname, email, locale);

        const response = await fetch(sendMessageUrl, {
            method: 'POST',
            headers: sendHeader,
            body: JSON.stringify(messageRequestBody)
        }).catch((e) => {
            console.error(e);
            setServerSideErrorMessage(SpotlightWaitlistConstants.error + SpotlightWaitlistConstants.failedToRegister);
        });

        return response.status === 201 || response.status === 200;
    };

    const manageServerSideErrors = (object) => {
        // print server side error message
        if(object && object.error && object.error.message) {
            if ((object.error.code === 'EXCEED_EMAIL_LIMIT') && emailExceedLimitLabel) {
                setServerSideErrorMessage(emailExceedLimitLabel);
            } else {
                setServerSideErrorMessage(object.error.message);
            }
        } else {
            setServerSideErrorMessage(false);
        }

        setIsSubmitting(false);
    };

    const validateFormInputs = (inputs) => {
        let errors = [];
        for(let i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove('--error');
            if (inputs[i].hasAttribute('required')) {
                if (inputs[i].value.length === 0) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                } else if (inputs[i].hasAttribute('pattern')) {
                    if (checkPattern(inputs[i])) {
                        errors.push({id: inputs[i].getAttribute('id')});
                        inputs[i].classList.add('--error');
                    }
                }
            } else if (inputs[i].value.length > 0 && inputs[i].hasAttribute('pattern')) {
                if (checkPattern(inputs[i])) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                }
            }
        }

        return errors;
    };

    const handleInputBlur = (event) => {
        event.preventDefault();
        setisInputBlur(true);
        const input = event.currentTarget;
        const errors = validateFormInput(input);

        if (errors) {
           setErroneousInputs([...errors]);
        }
    };

    const validateFormInput = (input) => {
        const inputId = input.getAttribute('id');
        let errors = erroneousInputs.filter((object) => object.id !== inputId);

        if (input.hasAttribute('required')) {
            if (input.value.length === 0) {
                errors.push({ id: inputId });
                input.classList.add('--error');
            } else if (input.hasAttribute('pattern')) {
                if (checkPattern(input)) {
                    errors.push({ id: inputId });
                    input.classList.add('--error');
                } else {
                    input.classList.remove('--error');
                }
            } else {
                input.classList.remove('--error');
            }
        } else if (input.value.length > 0 && input.hasAttribute('pattern')) {
            if (checkPattern(input)) {
                errors.push({ id: inputId });
                input.classList.add('--error');
            } else {
                input.classList.remove('--error');
            }
        } else {
            input.classList.remove('--error');
        }

        return errors;
    }

    const handleWheelScroll = (event) => {
        const element = event.currentTarget;
        const deltaY = event.deltaY;
        if ((element.scrollHeight - element.scrollTop) === element.clientHeight) {
            if (deltaY >= 100) {
                manageScrollToNextSection();
            }
        } else {
            canScrollToNextSection = false;
        }
    };

    const touchStart = (event) => {
        endTouchPoint = null;
        startTouchPoint = event.touches[0];
    };

    const touchEnd = () => {
        if (endTouchPoint != null && startTouchPoint != null) {
            let deltaX = startTouchPoint.screenX - endTouchPoint.screenX;
            let deltaY = startTouchPoint.screenY - endTouchPoint.screenY;

            if (isVerticalScroll(deltaX, deltaY, PAGE_SCROLL_DELTA_THRESHOLD)) {
                if ((blockRef.current.scrollHeight - blockRef.current.scrollTop) <= blockRef.current.clientHeight) {
                    manageScrollToNextSection();
                } else {
                    // alert(blockRef.current.scrollHeight + ' ' + blockRef.current.scrollTop + ' ' + blockRef.current.clientHeight + ' ' + blockRef.current.offsetTop);
                    canScrollToNextSection = false;
                }
            }
        }

    };

    const touchMove = (event) => {
        endTouchPoint = event.touches[0];
    };

    const isVerticalScroll = (deltaX, deltaY, deltaThreshold = PAGE_SCROLL_DELTA_THRESHOLD) => {
        let moveX = Math.abs(deltaX) > deltaThreshold ? Math.abs(deltaX) : 0;
        let moveY = Math.abs(deltaY) > deltaThreshold ? Math.abs(deltaY) : 0;

        return moveY > moveX;
    };

    const manageScrollToNextSection = () => {
        if (!canScrollToNextSection) {
            debounceScrollDown();
        } else {
            canScrollToNextSection = false;
            onContestScrollDown();
        }
    };

    const debounceScrollDown = () => {
        if (scrollDownDebounceTimeout) {
            clearTimeout(scrollDownDebounceTimeout);
        }

        scrollDownDebounceTimeout = setTimeout(function () {
            canScrollToNextSection = true;
        }, 150);
    };

    const selectOptions = (optionField, index) => (
        <option key={`ob-select-${index}`} value={optionField?.value || ''}>{optionField?.label}</option>
    );

    const getCountryByName = (name) => {
        let country = {};

        if(countryList && name) {
            for(let i = 0; i < countryList.length; i++) {
                if(countryList[i]?.countryName && countryList[i]?.countryName.toLowerCase().indexOf(name.toLowerCase())) {
                    country = countryList[i];
                }
            }
        }

        return country;
    };

    const [selectedCountryOption, setSelectedCountryOption] = useState('');

    const hierarchyItem = (hierarchyElement, index) => {
        return (
            <div key={index} className='ob-spotlight_language-toggle-item'>
                { hierarchyElement?.fields?.className.indexOf('active') > -1 ? (
                    <ObLink className={`white ${hierarchyElement?.fields?.className}`} href={hierarchyElement?.fields?.url} disabled='true'>{hierarchyElement?.fields?.title}</ObLink>
                ) : (
                    <ObLink className={`white ${hierarchyElement?.fields?.className}`} href={hierarchyElement?.fields?.url}>{hierarchyElement?.fields?.title}</ObLink>
                )}
            </div>
        )
    }

    return (
        <div onWheel={handleWheelScroll} ref={blockRef} className={'ob-spotlightContentBlock ob-spotlight-contest js-modal-container'}>
            
            { hierarchy &&
                <div className='ob-spotlight_language-toggle-container'>
                    <div className='ob-spotlight_language-toggle'>
                        { hierarchy?.fields?.destinations.map((hierarchyElement, index) => {
                            return hierarchyItem(hierarchyElement,index)
                        })}
                    </div>
                </div>
            }
            <div className='ob-spotlight_contest-background'
                style={{position: 'absolute', top: '0', bottom: '0', width: '100%', height: '110%', zIndex: '0'}}>
                <Image forceBackground={true} image={(backgroundAsset && !isMedium) ? backgroundAsset : mobileBackgroundAsset} customStyles={{backgroundRepeat: 'no-repeat'}} />
            </div>
            <div className='ob-spotlight_banner'>
                <Image imageClassName='ob-spotlight_banner-brush-image' image={(bannerAsset && !isMedium) ? bannerAsset : bannerAssetMobile} customStyles={{backgroundRepeat: 'no-repeat'}} />
                <div className='ob-spotlight_banner-container'>
                    <Image imageClassName='ob-spotlight_banner-intro-image' image={oralBLogoAsset} customStyles={{backgroundRepeat: 'no-repeat'}} />
                    <Image imageClassName='ob-spotlight_banner-revolution-image' image={titleImage} customStyles={{backgroundRepeat: 'no-repeat'}} />
                </div>

            </div>
            <div className='ob-spotlight_contest-container'>
                <div className={'ob-spotlight-contest__title-image-buffer'}>
                    <span className='visuallyhidden'>{title}</span>
                </div>
                <div className={'ob-spotlight-contest__contest-title-container'}>
                    <h1 className={'ob-spotlight-contest__contest-title-text'}>
                    <span dangerouslySetInnerHTML={{__html: contestTitle}}></span>
                    {ioLogoAsset ? <span className={'ob-spotlight-contest__contest-title-io-logo'}><Image alt={ioLogoAsset?.fields?.alternateText} image={ioLogoAsset} /></span> : ''}
                    </h1>
                    {subtitle && <div dangerouslySetInnerHTML={{__html: subtitle}}></div>}
                </div>

                <div className={'ob-spotlight-contest__wrapper'}>
                    {formFields &&
                    <form className={'ob-spotlight-contest__form'} onSubmit={handleSubmit} noValidate>

                        {serverSideErrorMessage &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                {serverSideErrorMessage}
                            </div>
                        }

                        {erroneousInputs.length > 0 &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__form-error'}>
                                <span>
                                    <Label label={numberOfErrorsLabel} tokens={{ numErrors: erroneousInputs.length, plural: erroneousInputs.length > 1 ? 's' : '' }} />
                                </span>
                            </div>
                        }

                        <ul className={'ob-spotlight-contest__form-list'}>
                            {
                                formFields.map((formField, formFieldIndex) => {
                                    return (
                                        <li className='ob-spotlight-contest__form-list-item' key={`formField-${formFieldIndex}`}>

                                            { (formField?.fields?.options) ? (                                        
                                                <div className="select-container">
                                                    <select 
                                                        aria-label={formField?.fields?.label?.fields?.text}
                                                        required={formField?.fields?.validation?.fields?.required}
                                                        className='select-css' 
                                                        id={formField?.fields?.id}
                                                        onBlur={event => handleInputBlur(event)}
                                                        data-value={selectedCountryOption}
                                                        onChange={event => setSelectedCountryOption(event.target.value)} >
                                                        { formField?.fields?.options.map((option, index) => selectOptions(option.fields, index))}
                                                    </select>
                                                </div>
                                            ) : (
                                                <>
                                                    <input
                                                        type={formField.fields.type}
                                                        aria-label={formField.fields.label.fields.text}
                                                        placeholder={formField.fields.label.fields.text}
                                                        pattern={formField?.fields?.validation?.fields?.pattern}
                                                        required={formField?.fields?.validation?.fields?.required}
                                                        id={formField.fields.id}
                                                        name={formField.fields.id}
                                                        className={'ob-spotlight-contest__form-input'}
                                                        onBlur={(event) => {handleInputBlur(event)}} />

                                                    { formField?.fields?.fieldMention &&
                                                        <p className={'ob-spotlight-contest__form-mention'}>
                                                            {formField.fields.fieldMention}
                                                        </p>
                                                    }
                                                </>

                                            )}

                                            {erroneousInputs.length > 0 &&
                                            erroneousInputs.map((error, index) => {
                                                return error.id === formField.fields.id && formField?.fields?.validation?.fields?.errorMessage &&
                                                    <p key={index} className={'ob-spotlight-contest__form-error'}>
                                                        {formField.fields.validation.fields.errorMessage}
                                                    </p>
                                            })}
                                        </li>
                                    )
                                })
                            }

                        </ul>
                        


                        <div className={'ob-spotlight-contest__checkboxes-section'}>
                            {formOptinInputText &&
                            <div
                                className={'ob-spotlight-contest__checkbox-opt-in'}
                                dangerouslySetInnerHTML={{__html: formOptinInputText}}>
                            </div>
                            }
                            {formOwnETBInputText &&
                            <div
                                className={'ob-spotlight-contest__checkbox-own-etb'}
                                dangerouslySetInnerHTML={{__html: formOwnETBInputText}}>
                            </div>
                            }
                        </div>
                        <div
                            className={'ob-spotlight-contest__legal-text ob-spotlight-contest__legal-text-not-US'}
                            dangerouslySetInnerHTML={{__html: formLegalText}}>
                        </div>
                        <button
                            type={SpotlightContestConstants.submit}
                            className={'ob-button event_profile_email_signup not-us'}
                            data-action-detail={`${signupCallToActionLabel.fields.text} submit`}
                            disabled={isSubmitting}>
                            <span>{signupCallToActionLabel.fields.text}</span>
                            {isSubmitting &&
                            <span className={'ob-button__spinner'}></span>
                            }
                        </button>
                    </form>
                    }

                    <div className={'ob-spotlight-contest__button-scroll-down-container'}>
                        <button className={'ob-spotlight-contest__button-scroll-down'} onClick={onContestScrollDown}>
                            <span dangerouslySetInnerHTML={{__html: arrowTitle}}></span>
                            <Icon name={SpotlightContestConstants.chevronDown} size={ isMedium ? '4' : '5.8'} color={'white'} />
                        </button>
                    </div>
                </div>

            </div>

            {(modalLabelFirst || modalLabelSecond) &&
                <Modal
                    isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                    style={customStylesLegal}
                    onRequestClose={closeFirstLegalModal}
                    closeTimeoutMS={250}
                    overlayClassName={'event_button_click'}
                    ref={firstLegalModalRef}
                >
                    <div className='ob-spotlight-waitlist__modal-container-legal'>

                        { isFirstLegalModalOpen && modalLabelFirst &&
                        <p dangerouslySetInnerHTML={{__html: modalLabelFirst}}></p>
                        }

                        { isSecondLegalModalOpen && modalLabelSecond &&
                        <p dangerouslySetInnerHTML={{__html: modalLabelSecond}}></p>
                        }

                        <button className='event_close_window ob-modal-close'
                            onClick={closeFirstLegalModal}
                            aria-label={closeModalLabel} type='button'>
                            <Icon name='close' size='2.4' color='#000' />
                        </button>

                    </div>
                </Modal>
            }
        </div>
    )
}

export default mediaQueryHOC(SpotlightContest);
// This export is for unit testing (do not remove) :
export const SpotlightContestTest = SpotlightContest;

SpotlightContest.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool,
    index: PropTypes.any
};
