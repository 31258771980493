import getConfig from 'next/config';
import algoliasearch from 'algoliasearch'
const { publicRuntimeConfig = {} } = getConfig() || {};
import { AlgoliaConstants } from '../helpers/Constants'

const clients = {
  search: null,
  write: null
}

export const getSearchClient = () => {
  if (!publicRuntimeConfig.ALGOLIA_APP_ID || !publicRuntimeConfig.ALGOLIA_APP_SEARCH_KEY) {
    throw new Error(AlgoliaConstants.errorMsg)
  }

  if (!clients.search) {
    clients.search = algoliasearch(publicRuntimeConfig.ALGOLIA_APP_ID, publicRuntimeConfig.ALGOLIA_APP_SEARCH_KEY)
  }

  return clients.search
}

