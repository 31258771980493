import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Block from '../../adapters/helpers/Block';
import {mediaQueryHOC} from '../../adapters/helpers/Hooks';
import HierarchyUtils from '../../adapters/helpers/HierarchyUtils';

import './SitemapBlock.scss';

class SitemapBlock extends Component {

    constructor(props) {
        super(props);
        this.block = new Block(props);
        this.className = this.block.getFieldValue('classNames');
        this.id = this.block.getAnchorId();
        this.hierarchy = this.block.getFieldValue('hierarchy');
        this.properties = this.getHierarchyProperties(this.hierarchy);

    }

    getHierarchyProperties(hierarchy) {
        const destinations = hierarchy.fields.destinations;
        const title = hierarchy.fields.title;
        const hierarchyId = HierarchyUtils.getHierarchyId(hierarchy);
        const isSubNav = hierarchy.fields.type === 'product-by-need' || hierarchy.fields.type === 'product-by-type' || hierarchy.fields.type === 'why-oral-b' || hierarchy.fields.type === 'conditions' || hierarchy.fields.type === 'life-stages';
        const isMainMenu = hierarchyId === 'Hierarchy---Main-Menu';
        const isSubNavCtn = !isMainMenu && !isSubNav;

        let itemClassNames = !isSubNav ? 'ob-main-menu__list--item' : 'ob-sub-navigation--item';
        let mainMenuClassNames = isMainMenu ? 'ob-main-menu' : '';
        let subNavigationListClassNames = isSubNav ? 'ob-sub-navigation__list' : '';
        let iconPosition = null;
        const mainMenuListClassNames = isSubNavCtn ? 'ob-main-menu__subNav--ctn' : '';
        const listClassNames = `ob-main-menu__list ${mainMenuListClassNames}`;
        const onlyShowOnClassNames = hierarchy.fields.onlyShowOn ? `ob-main-menu__list--${hierarchy.fields.onlyShowOn}` : '';

        if (isSubNavCtn) {
            itemClassNames = ' ob-sub-navigation';
            iconPosition = 'after';
        }

        return {
            destinations,
            title,
            hierarchyId,
            isSubNav,
            isMainMenu,
            isSubNavCtn,
            itemClassNames,
            mainMenuClassNames,
            subNavigationListClassNames,
            iconPosition,
            mainMenuListClassNames,
            listClassNames,
            onlyShowOnClassNames
        };
    }

    renderHierarchy() {
        return (
            <div className={'ob-sitemap__container'}>
                <h1 className={'ob-sitemap__title'}>{this.properties.title}</h1>
                <ul className={'ob-sitemap__list'}>
                    {
                        this.renderDestination(this.properties, 'chevronRight')
                    }
                </ul>
            </div>
        )
    }

    renderDestination(properties) {
        return properties.destinations.map((dest, index) => (
            <li className={'ob-sitemap__list-item'} key={index}>
                {HierarchyUtils.renderDestination(this.hierarchy.fields.destinations[index])}
            </li>
        ))
    }

    render() {
        return (
            <div className={'ob-sitemap'}>
                {
                    this.renderHierarchy()
                }
            </div>
        )
    }
}

export default mediaQueryHOC(SitemapBlock);

SitemapBlock.propTypes = {
    extraAttributes: PropTypes.any,
    isMedium: PropTypes.any,
};
