import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Block from '../../adapters/helpers/Block';
import Icon from '../Icon/Icon'
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import {TabbedContentConstants} from '../../adapters/helpers/Constants';
import './TabbedContent.scss';

class TabbedContent extends Component {
    
    constructor(props) {
        super(props);
        this.block = new Block(props);
        
        this.state = {
            currentTab : TabbedContentConstants.empty,
        }
        this.isLarge = props.isLarge;
        this.clickTab = this.clickTab.bind(this);
    }
    
    renderTab(block, index, renderTabContent = null) {
        const blockObj = new Block(block);
        const tabId = this.getTabId(blockObj);
        blockObj.setFieldValue(TabbedContentConstants.anchorName, tabId);
        
        const label = blockObj.getShortName();
        
        return (
            <div key={tabId + index} className={`${this.state.currentTab === tabId ? 'is-active ' : ''}ob-tabbed-content__tab-list-item`}>
                <button data-tab-id={tabId} onClick={this.clickTab}>
                    {label}
                    <span className={'ob-tabbed-content__tab-icon'}><Icon name={TabbedContentConstants.chevronDown} /></span>
                </button>
                
                {renderTabContent && renderTabContent()}
            </div>
        );
    }
    
    renderTabContent(block, index) {
        const blockObj = new Block(block);
        const tabId = this.getTabId(blockObj);
        blockObj.setFieldValue(TabbedContentConstants.anchorName, tabId);
        if (index === 0 && this.state.currentTab === '') {
            this.setState({currentTab: tabId});
        }
        
        return (
            <div key={tabId + index} id={tabId} className={`${this.state.currentTab === tabId ? 'is-active ' : ''}ob-tabbed-content__content-list-item`}>
                {blockObj.renderBlockFromDocument()}
            </div>
        );
    }
    
    getTabId(blockObj) {
        return TabbedContentConstants.tab + blockObj.getAnchorId();
    }
    
    clickTab(event) {
        event.preventDefault();
        this.setState({currentTab: event.currentTarget.dataset.tabId})
    }
    
    render() {
        let className = 'ob-tabbed-content ' + this.props.className;
        return (this.isLarge ? (
            <div className={className}>
                <div className={'ob-tabbed-content__tab-list'}>
                    {
                        this.block.getFieldValue(TabbedContentConstants.blocks).map((block, index) => {
                            return this.renderTab(block, index, () => this.renderTabContent(block, index));
                        })
                    }
                </div>
            </div>
        ) : (
            <div className={className}>
                <div className={'ob-tabbed-content__tab-list'}>
                    {
                        this.block.getFieldValue(TabbedContentConstants.blocks).map((block, index) => {
                            return this.renderTab(block, index);
                        })
                    }
                </div>
                <ul className={'ob-tabbed-content__content-list'}>
                    {
                        this.block.getFieldValue(TabbedContentConstants.blocks).map((block, index) => {
                            return this.renderTabContent(block, index);
                        })
                    }
                </ul>
            </div>
        ));
    }
}

TabbedContent.propTypes = {
    className: PropTypes.string,
    isLarge: PropTypes.bool,
};

export default mediaQueryHOC(TabbedContent);
// This export is for unit testing (do not remove) :
export const TabbedContentTest = TabbedContent;