import { LOCAL_STORAGE_KEYS } from './localStorage';
import { first } from '../adapters/helpers/functions';

const PRODUCT_PERSISTENCE_LIFESPAN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

function findProductIndex(products, id) {
    for(let i = 0; i < products.length; i++) {
        const product = products[i];

        if(product.id === id) {
            return i;
        }
    }

    return undefined;
}

function filterExpiredProducts(products) {
    return products.filter(({expiral}) =>
            new Date(expiral).getTime() > new Date().getTime())
}


// TODO: Remove in prod (Used for data consistency between changes)
function isProductsDataValid(products) {
    if(!Array.isArray(products)) { return false; }

    for(let i = 0; i < products.length; i++) {
        const product = products[i];

        if(!product) { return false; }

        if(!product.hasOwnProperty('id')) { return false; }
        if(!product.hasOwnProperty('expiral')) { return false; }

        if(!(typeof product.id === 'string')) { return false; }
        if(!(typeof product.expiral === 'number')) { return false; }
    }
    return true;
}

export function setViewed(id) {
    if((typeof localStorage !== 'undefined') && localStorage.setItem) {
        const products = getProducts();

        // Remove already viewed product
        const productsWithoutCurrentProduct = products.slice();
        const currentProductIndex = findProductIndex(products, id);

        if(currentProductIndex !== undefined) {
            productsWithoutCurrentProduct.splice(currentProductIndex, 1);
        }

        // Filter expirered products
        const expiralFilteredProduct = filterExpiredProducts(productsWithoutCurrentProduct);

        // Add id to first place
        const nextProducts = first([
            { id, expiral: (new Date().getTime() + PRODUCT_PERSISTENCE_LIFESPAN_MILLISECONDS)},
            ...expiralFilteredProduct
        ], 3);

        localStorage.setItem(LOCAL_STORAGE_KEYS.RECENTLY_VIEWED_PRODUCTS, JSON.stringify(nextProducts));
    }
}

export function getProducts() {
    if((typeof localStorage !== 'undefined') && localStorage.getItem) {
        const json = localStorage.getItem(LOCAL_STORAGE_KEYS.RECENTLY_VIEWED_PRODUCTS);

        if(json !== null) {
            try {
                const products = JSON.parse(json);

                // TODO: Remove in prod
                if(!isProductsDataValid(products)) { return [];}

                return filterExpiredProducts(products);
            } catch(_e) {
                // Do nothing or log error
            }
        }
    }

    return [];
}

export function removeProduct(id) {
    if((typeof localStorage !== 'undefined') && localStorage.setItem) {
        const products = getProducts();

        // Remove product
        const productsWithoutCurrentProduct = products.slice();
        const currentProductIndex = findProductIndex(products, id);

        if(currentProductIndex !== undefined) {
            productsWithoutCurrentProduct.splice(currentProductIndex, 1);
        }

        // Filter expirered products
        const expiralFilteredProduct = filterExpiredProducts(productsWithoutCurrentProduct);

        localStorage.setItem(LOCAL_STORAGE_KEYS.RECENTLY_VIEWED_PRODUCTS, JSON.stringify(expiralFilteredProduct));
    }
}
