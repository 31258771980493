import React, { useState, useEffect } from 'react'
import './DoubleOptInVerification.scss';
import {setEntityOptInStatus} from '../../../../adapters/model/service/api/svc';
import PropTypes from 'prop-types';

function DoubleOptInVerification({title, successText, failureText}) {

    const [isVerify, setIsVerify] = useState(false);
    const [formProcessing, setFormProcessing] = useState(true);

    const onVerificationSuccess = () => {
        setFormProcessing(false);
        setIsVerify(true);
    };

    const onVerificationFailure = () => {
        setFormProcessing(false);
        setIsVerify(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (
                typeof window !== 'undefined' &&
                window.janrain &&
                window.janrain.capture &&
                window.janrain.capture.ui
            ){
                window.janrain.capture.ui.renderScreen('verifyEmail');
                window.janrain.events.onCaptureEmailVerificationSuccess.addHandler(function(event){
                    setEntityOptInStatus(true, event.accessToken, onVerificationSuccess, onVerificationFailure);
                })
                window.janrain.events.onCaptureEmailVerificationFailed.addHandler(function(event){
                    console.error(event);
                    onVerificationFailure();
                })
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
            <div>
                <div className='double_optin_verification_container'>
                    <div className='double_optin_verification_title'>
                        {title}
                    </div>
                    {isVerify && !formProcessing &&
                        <div className='double_optin_verification_success_text'>
                            {successText}
                        </div>
                    }
                    {!isVerify && !formProcessing &&
                        <div className='double_optin_verification_success_text'>
                            {failureText}
                        </div>
                    }
                </div>
            </div>
    )
}

DoubleOptInVerification.propTypes = {
    title: PropTypes.string,
    successText: PropTypes.string,
    failureText: PropTypes.string
};

export default DoubleOptInVerification
