import StarRating from '../StarRating/StarRating';
import {getConfigurationBooleanValue, getConfigurationValue} from '../../adapters/helpers/ContentBlockConfiguration';
import BodyText from '../BodyText/BodyText';
import Button from '../Button/Button';
import BackgroundWrapper from '../Content/BackgroundWrapper';
import Image from '../Image/Image';
import React, {Component} from 'react';
import {Waypoint} from 'react-waypoint';
import PropTypes from 'prop-types';
import {ContentConstants, PressQuoteBlockConstants} from '../../adapters/helpers/Constants';
import './PressQuote.scss';
import {getRGBFromHexColor} from '../../adapters/helpers/Utils';

class PressQuote extends Component {
    constructor(props) {
        super(props);
        this.pressLogo = this.valueOf(PressQuoteBlockConstants.pressLogo);
        this.quote = this.valueOf(PressQuoteBlockConstants.quote);
        this.starRating = parseFloat(this.valueOf(PressQuoteBlockConstants.starRating)) || 0;
        this.animationGifAsset = this.valueOf(PressQuoteBlockConstants.animationGifAsset);
        this.textContainerBackgroundColor = null;
        this.textContainerHasPadding = this.valueOf(ContentConstants.textContainerHasPadding);
        this.isWhiteText = false;

        this.availableConfigurations = this.valueOf(ContentConstants.deviceConfigurations).map(configuration => configuration.fields.deviceType);

        this.state = {
            currentIndex: 0,
            show: ''
        };
    }

    valueOf(name) {
        let value = undefined;
        if (name && this.props.quote && this.props.quote.fields && this.props.quote.fields[name] !== undefined) {
            value = this.props.quote.fields[name];
        }
        return value;
    }

    generateContentblockState() {
        return {
            availableConfigurations: this.valueOf(ContentConstants.deviceConfigurations).map(configuration => configuration.fields.deviceType),
            blockMinHeight: this.configurationValue(ContentConstants.blockMinHeight, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            blockMarginTop: this.configurationValue(ContentConstants.blockMarginTop, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            blockMarginBottom: this.configurationValue(ContentConstants.blockMarginBottom, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            blockMarginRight: this.configurationValue(ContentConstants.blockMarginRight, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            blockMarginLeft: this.configurationValue(ContentConstants.blockMarginLeft, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textAlign: this.configurationValue(ContentConstants.textAlign, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textContainerWidth: this.configurationValue(ContentConstants.textContainerWidth, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            titleWidth: this.configurationValue(ContentConstants.titleWidth, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            descriptionWidth: this.configurationValue(ContentConstants.descriptionWidth, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textContainerHorizontalAlignment: this.configurationValue(ContentConstants.textContainerHorizontalAlignment, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textContainerVerticalAlignment: this.configurationValue(ContentConstants.textContainerVerticalAlignment, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textContainerOffset: this.configurationValue(ContentConstants.textContainerOffset, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textContainerMarginRight: this.configurationValue(ContentConstants.textContainerMarginRight, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textContainerMarginLeft: this.configurationValue(ContentConstants.textContainerMarginLeft, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textContainerMarginBottom: this.configurationValue(ContentConstants.textContainerMarginBottom, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAsset: this.configurationValue(ContentConstants.mainAsset, this.props.isMedium, this.props.isXSmall, this.props.isXLarge,),
            mainAssetMaxWidth: this.configurationValue(ContentConstants.mainAssetMaxWidth, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetMaxHeight: this.configurationValue(ContentConstants.mainAssetMaxHeight, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetHeight: this.configurationValue(ContentConstants.mainAssetHeight, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetOffsetTop: this.configurationValue(ContentConstants.mainAssetMarginTop, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetOffsetRight: this.configurationValue(ContentConstants.mainAssetMarginRight, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetOffsetBottom: this.configurationValue(ContentConstants.mainAssetOffsetBottom, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetOffsetLeft: this.configurationValue(ContentConstants.mainAssetOffsetLeft, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetScale: this.configurationValue(ContentConstants.mainAssetScale, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            backgroundAsset: this.configurationValue(ContentConstants.backgroundAsset, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
            mainAssetHorizontalAlignment: this.configurationValue(ContentConstants.mainAssetHorizontalAlignment, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
            mainAssetVerticalAlignment: this.configurationValue(ContentConstants.mainAssetVerticalAlignment, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
            backgroundPosition: this.configurationValue(ContentConstants.backgroundPosition, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            sectionOffsetTop: this.configurationValue(ContentConstants.sectionOffsetTop, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetParallax: this.configurationValue(ContentConstants.mainAssetParallax, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            mainAssetParallaxSetting: this.configurationValue(ContentConstants.mainAssetParallaxSetting, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textParallax: this.configurationValue(ContentConstants.textParallax, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textParallaxSetting: this.configurationValue(ContentConstants.textParallaxSetting, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textParallaxConfiguration: this.configurationValue(ContentConstants.textParallaxConfiguration, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            assetParallaxConfiguration: this.configurationValue(ContentConstants.assetParallaxConfiguration, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textShowAnimation: this.configurationValue(ContentConstants.textShowAnimation, this.props.isMedium, this.props.isXSmall, this.props.isXLarge, ''),
            textFadeIn: this.configurationBooleanValue(ContentConstants.textFadeIn, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
            textColor: this.configurationBooleanValue(ContentConstants.textColor, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
            respectImageAspectRatio: this.configurationBooleanValue(ContentConstants.respectImageAspectRatio, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
            textContainerBackgroundColor: this.configurationValue(ContentConstants.textContainerBackgroundColor, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
            textContainerBackgroundOpacity: this.configurationValue(ContentConstants.textContainerBackgroundOpacity, this.props.isMedium, this.props.isXSmall, this.props.isXLarge),
        };
    }

    configurationValue(fieldName, isMedium, isXSmall, isXLarge, defaultValue = false) {
        return getConfigurationValue(this.availableConfigurations,
            this.valueOf(ContentConstants.deviceConfigurations),
            fieldName,
            isMedium,
            isXSmall,
            isXLarge,
            defaultValue);
    }

    configurationBooleanValue(fieldName, isMedium, isXSmall, isXLarge, defaultValue = false) {
        return getConfigurationBooleanValue(this.availableConfigurations,
            this.valueOf(ContentConstants.deviceConfigurations),
            fieldName,
            isMedium,
            isXSmall,
            isXLarge,
            defaultValue);
    }

    _handleWaypointEnter() {
        // Trigger by waypoint enter
        this.showContentElement();
    }

    showContentElement() {
        // Animation on the text container on the 'onEnter' event
        this.setState({show: 'show'});
    }

    render() {
        let contentBlockState = this.generateContentblockState();
        this.isWhiteText = (contentBlockState.textColor) === ContentConstants.White ? true : false;
        let textContainerBackgroundOpacity = 1;
        if (contentBlockState.textContainerBackgroundOpacity) {
            textContainerBackgroundOpacity = contentBlockState.textContainerBackgroundOpacity;
        }
        if (contentBlockState.textContainerBackgroundColor) {
            let colorRgb = getRGBFromHexColor(contentBlockState.textContainerBackgroundColor);
            this.textContainerBackgroundColor = `rgba(${colorRgb.red}, ${colorRgb.green}, ${colorRgb.blue}, ${textContainerBackgroundOpacity})`;
        }

        const ctaBackgroundColor = this.valueOf(ContentConstants.ctaBackgroundColor);

        return (
            <section
                className={
                    `ob-pressQuoteItem content ${contentBlockState.textShowAnimation} ${contentBlockState.backgroundAsset ? '' : 'no-background'}`
                }
                data-respectimageaspectratio={contentBlockState.respectImageAspectRatio}>

                <BackgroundWrapper
                    backgroundAsset={contentBlockState.backgroundAsset}
                    sectionOffsetTop={contentBlockState.sectionOffsetTop}
                    backgroundPosition={contentBlockState.backgroundPosition}
                >
                    <div className={`ob-pressQuoteItem-wrapper ${this.state.show}`}>
                        {contentBlockState.textFadeIn !== false &&
                            <Waypoint onEnter={this._handleWaypointEnter.bind(this)}/>
                        }

                        <div className={`ob-pressQuoteItem-textContent
                            ${contentBlockState.textContainerHorizontalAlignment && `horizontal-${contentBlockState.textContainerHorizontalAlignment}`}
                            ${contentBlockState.textContainerVerticalAlignment && `vertical-${contentBlockState.textContainerVerticalAlignment}`}
                            `}>

                            <div
                                className={`ob-pressQuoteItem-textContent-inner${this.textContainerHasPadding ? ' hasPadding' : ''} `}
                                style={{
                                    marginTop: contentBlockState.textContainerOffset ? contentBlockState.textContainerOffset : null,
                                    marginBottom: contentBlockState.textContainerMarginBottom ? contentBlockState.textContainerMarginBottom : null,
                                    marginLeft: contentBlockState.textContainerMarginLeft ? contentBlockState.textContainerMarginLeft : null,
                                    marginRight: contentBlockState.textContainerMarginRight ? contentBlockState.textContainerMarginRight : null,
                                    backgroundColor: this.textContainerBackgroundColor ? this.textContainerBackgroundColor : null,
                                    textAlign: contentBlockState.textAlign ? contentBlockState.textAlign : null,
                                    width: contentBlockState.textContainerWidth ? contentBlockState.textContainerWidth : null
                                }}>

                                <div className='ob-pressQuoteItem-text-image'>
                                    <Image image={this.pressLogo}/>
                                </div>


                                {(this.starRating && this.starRating > 0) ? (
                                        <div className={`ob-pressQuoteItem-reviewStars ${this.starRating}`}>
                                            <StarRating ratingNumber={this.starRating} smallSize={2} mediumSize={2}
                                                largeSize={2}/>
                                        </div>
                                    ) : null
                                }

                                <BodyText
                                    whiteText={this.isWhiteText}
                                    styles={{width: contentBlockState.descriptionWidth ? contentBlockState.descriptionWidth : null}}>
                                    {this.quote}
                                </BodyText>

                                <div className='ob-pressQuoteItem-textContent-obLink-container'>
                                    <Button
                                        whiteTheme={this.isWhiteText || ctaBackgroundColor === ContentConstants.white}
                                        borderTheme={!this.isWhiteText && ctaBackgroundColor === PressQuoteBlockConstants.transparentWithBorders}
                                        invertedTheme={this.isWhiteText && ctaBackgroundColor === PressQuoteBlockConstants.transparentWithBorders}
                                        tag='a'
                                        className={'event_external_link'}
                                        dataActionDetail={this.valueOf(PressQuoteBlockConstants.callToActionLink)}
                                        href={this.valueOf(PressQuoteBlockConstants.callToActionLink)}>
                                        {this.valueOf(PressQuoteBlockConstants.callToActionLabel)}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {contentBlockState.mainAsset &&
                            <div className='ob-pressQuoteItem-mainAsset-container' style={{
                                width: contentBlockState.mainAssetMaxWidth ? contentBlockState.mainAssetMaxWidth : null,
                                height: contentBlockState.mainAssetHeight ? contentBlockState.mainAssetHeight  : null,
                                maxHeight: contentBlockState.mainAssetMaxHeight ? contentBlockState.mainAssetMaxHeight : null,
                                marginRight: contentBlockState.mainAssetOffsetRight ? contentBlockState.mainAssetOffsetRight : null,
                                marginLeft: contentBlockState.mainAssetOffsetLeft ? contentBlockState.mainAssetOffsetLeft : null,
                                marginTop: contentBlockState.mainAssetOffsetTop ? contentBlockState.mainAssetOffsetTop : null,
                                marginBottom: contentBlockState.mainAssetOffsetBottom ? contentBlockState.mainAssetOffsetBottom : null,
                            }}>
                                <div style={{position: 'relative',height: '100%'}}>
                                    <Image
                                        scale={contentBlockState.mainAssetScale}
                                        image={contentBlockState.mainAsset}
                                        className={`
                                                ${contentBlockState.mainAssetHorizontalAlignment && `horizontal-${contentBlockState.mainAssetHorizontalAlignment}`}
                                                ${contentBlockState.mainAssetVerticalAlignment && `vertical-${contentBlockState.mainAssetVerticalAlignment}`}
                                        `} />

                                    {this.animationGifAsset &&
                                        <div className={'ob-pressQuoteItem-gifAnimation'}>
                                            <Image image={this.animationGifAsset} />
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </BackgroundWrapper>
            </section>
        )
    }
}

export default PressQuote;

PressQuote.propTypes = {
    isXSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isXLarge: PropTypes.bool,
    handleDotNavClick: PropTypes.func,
    quote: PropTypes.object,
};
