import {removeAnchorFromLink} from '../../adapters/helpers/Anchor';

export function getSelectedIndex(links, currentURL) {
    let selectedIndex = undefined;

    for(let i = 0 ; i < links.length; i++) {
        const link = links[i];
        const tabURL = link?.fields?.url;
        if(tabURL && removeAnchorFromLink(tabURL) === currentURL) {
            selectedIndex = i;
        }
    }

    return selectedIndex;
}

export function mapLinks(links) {
    return links?.map(link => ({
        text: link?.fields?.title,
        link: link?.fields?.url,
    })) || [];
}