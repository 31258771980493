import 'isomorphic-fetch';
import getConfig from 'next/config';
import {SearchServiceConstants} from '../../../../helpers/Constants';

class SearchService {

    async searchDocument(query) {
        const {publicRuntimeConfig} = getConfig();

        let registrationHeader = new Headers();
        registrationHeader.append(SearchServiceConstants.contentType, SearchServiceConstants.json);

        this.market = publicRuntimeConfig.MARKET.toLowerCase();
        this.swiftypeApiKey = publicRuntimeConfig.SWIFTYPE_READ_API_KEY;
        this.swiftypeBaseUrl = SearchServiceConstants.swiftypeBaseUrl;
        this.swiftypePath = SearchServiceConstants.swiftypePath;

        let url = this.swiftypeBaseUrl + this.swiftypePath;

        const params = {
            'engine_key': this.swiftypeApiKey,
            'q': query,
            'filters': {
                'product': {'market': this.market},
                'page': {'market': this.market},
                'article': {'market': this.market},
                'collection': {'market': this.market},
                'faq': {'market': this.market}
            }
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: registrationHeader,
            body: JSON.stringify(params)
        }).then();

        let queryResult = {};
        let searchResults = [];
        let searchCategories;

        const data = await response.json();

        // group so we can iterate on all results
        const categories = ['product', 'page', 'article', 'collection'];
        for (let category of categories) {
            searchResults = searchResults.concat(this.getEntriesForCategory(data, category));
        }

        // sort based on score
        searchResults.sort(this.compare);

        searchCategories = this.getResultCategories(data, ['faq']);

        queryResult.totalCount = data.record_count;
        queryResult.searchResults = searchResults;
        queryResult.searchResultsByCategory = data.records;
        queryResult.searchCategories = searchCategories;

        return queryResult;
    }

    async suggestResults(query, excludedCategories) {
        const {publicRuntimeConfig} = getConfig();

        let registrationHeader = new Headers();
        registrationHeader.append(SearchServiceConstants.contentType, SearchServiceConstants.json);

        this.market = publicRuntimeConfig.MARKET;
        this.swiftypeApiKey = publicRuntimeConfig.SWIFTYPE_READ_API_KEY;
        this.swiftypeAutocompleteUrl = SearchServiceConstants.swiftypeAutocompleteUrl;

        let url = this.swiftypeAutocompleteUrl;
        let documentTypes = [];
        const categories = ['product', 'page', 'article', 'collection', 'faq'];

        for (let category of categories) {
            if (excludedCategories === undefined || excludedCategories.indexOf(category.toLowerCase()) < 0) {
                documentTypes.push(category);
            }
        }

        const params = {
            'engine_key': this.swiftypeApiKey,
            'q': query,
            'document_types': documentTypes,
            'filters': {
                'product': {'market': [this.market]},
                'page': {'market': [this.market]},
                'article': {'market': [this.market]},
                'collection': {'market': [this.market]},
                'faq': {'market': [this.market]}
            }
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: registrationHeader,
            body: JSON.stringify(params)
        }).then();

        let queryResult = {};
        let searchResults = [];
        let searchCategories;

        const data = await response.json();

        // group so we can iterate on all results
        for (let category of categories) {
            if (excludedCategories === undefined || excludedCategories.indexOf(category.toLowerCase()) < 0) {
                searchResults = searchResults.concat(this.getEntriesForCategory(data, category));
            }
        }

        // sort based on score
        searchResults.sort(this.compare);

        searchCategories = this.getResultCategories(data, excludedCategories);

        queryResult.totalCount = data.record_count;
        queryResult.searchResults = searchResults;
        queryResult.searchResultsByCategory = data.records;
        queryResult.searchCategories = searchCategories;

        return queryResult;
    }

    async searchFaq(query) {
        const {publicRuntimeConfig} = getConfig();

        let registrationHeader = new Headers();
        registrationHeader.append(SearchServiceConstants.contentType, SearchServiceConstants.json);

        this.market = publicRuntimeConfig.MARKET.toLowerCase();
        this.swiftypeApiKey = publicRuntimeConfig.SWIFTYPE_READ_API_KEY;
        this.swiftypeBaseUrl = SearchServiceConstants.swiftypeBaseUrl;
        this.swiftypePath = SearchServiceConstants.swiftypePath;

        let url = this.swiftypeBaseUrl + this.swiftypePath;

        const params = {
            'engine_key': this.swiftypeApiKey,
            'q': query,
            'document_types': ['faq'],
            'filters': {
                'faq': {'market': [this.market]}
            }
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: registrationHeader,
            body: JSON.stringify(params)
        }).then();

        let queryResult = {};
        let searchResults = [];
        const data = await response.json();

        // group so we can iterate on all results
        searchResults = searchResults.concat(this.getEntriesForCategory(data, 'faq'));

        // sort based on score
        searchResults.sort(this.compare);

        queryResult.totalCount = data.record_count;
        queryResult.searchResults = searchResults;

        queryResult = this.setFaqCategoriesAndResultsByCategory(queryResult, data.records);

        return queryResult;
    }

    setFaqCategoriesAndResultsByCategory(queryResult, records) {
        let searchCategories = [];
        let searchResultsByCategory = {};

        for (let record in records) {
            for (let faq of records[record]) {
                if (faq?.categories) {
                    for (let category of faq.categories) {
                        if (searchResultsByCategory[category.toLowerCase()] === undefined) {
                            searchResultsByCategory[category.toLowerCase()] = [];
                        }
                        searchResultsByCategory[category.toLowerCase()].push(faq);

                        if (searchCategories.indexOf(category) < 0) {
                            searchCategories.push(category);
                        }
                    }
                }
            }
        }

        queryResult.searchResultsByCategory = searchResultsByCategory;
        queryResult.searchCategories = searchCategories;

        return queryResult;
    }

    getEntriesForCategory(data, category) {
        let results = [];
        if (data.records[category]) {
            data.records[category].forEach(function (entry) {
                results.push(entry);
            });
        }
        return results;
    }

    getResultCategories(data, excludedCategories) {
        let categories = [];
        if (!excludedCategories) {
            excludedCategories = [];
        }

        if (data && data.info) {
            for (const property in data.info) {
                if (data.info[property].total_result_count > 0 && excludedCategories.indexOf(property) < 0) {
                    categories.push(this.getCapitalizedValue(property));
                }
            }
        }
        return categories;
    }

    getCapitalizedValue(value) {
        let capitalized = '';
        if (value) {
            capitalized = value.substr(0, 1).toUpperCase() + value.substr(1).toLowerCase();
        }
        return capitalized;
    }

    encodeQueryData(data) {
        const ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }

    compare(a, b) {
        if (a._score > b._score)
            return -1;
        if (a._score < b._score)
            return 1;
        return 0;
    }

}

export default new SearchService();
// This export is for unit testing (do not remove) :
export const SearchServiceTest = SearchService;
