import Block from '../../adapters/helpers/Block';
import React from 'react';
import OnePageScroll from '../OnePageScroll/OnePageScroll';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import {SpotlightSliderConstants} from '../../adapters/helpers/Constants';
import './spotlight-custom.scss';

function SpotlightSlider(props) {
    const {isMedium, onClickCallback} = props;
    const block = new Block(props);
    /*eslint-disable */
    let _pageScroller = null;
    /*eslint-enable */
    let extraAttributes = props.extraAttributes || {};
    let className = SpotlightSliderConstants.scrollerContainer + block.getFieldValue(SpotlightSliderConstants.classNames);
    const continueReadingLabel = block.getFieldValue(SpotlightSliderConstants.continueReadingLabel)?.fields?.text;

    return (
        <div className={className} role={SpotlightSliderConstants.main}>
            <OnePageScroll forwardRef={c => _pageScroller = c} arrowLabel={continueReadingLabel} onClickCallback={props.onClickCallback} customEvent={props.customEvent} isMobile={isMedium}>
                {block.renderChildBlocks({extraAttributes: extraAttributes}, onClickCallback, isMedium)}
            </OnePageScroll>
        </div>
    )
}

export default mediaQueryHOC(SpotlightSlider)

SpotlightSlider.propTypes = {
    className: PropTypes.string,
    extraAttributes: PropTypes.any,
    isMedium: PropTypes.any,
    customEvent: PropTypes.any,
    onClickCallback: PropTypes.func
};
