import React from 'react'
import Icon from '../Icon/Icon'
import PropTypes from 'prop-types'
import {StarRatingConstants} from '../../adapters/helpers/Constants';
import './StarRating.scss'

export default function StarRating({ratingNumber = 5, color = '#FFB702', smallSize = 3.5, mediumSize = 5, largeSize = 6, className = ''}) {
    // Return 5 starsRating
    // ratingNumber must be 3, 3.5, 4, 4.5. Default is 5
    // color must be rgb/a or hexa color as #FFB702

    const starSizes = {
        0: smallSize,
        1: mediumSize,
        2: largeSize,
        3: mediumSize,
        4: smallSize
    };
    let stars = [];

    for (var i = 0; i < 5; i++) {

        let name = StarRatingConstants.star;

        if (i == 3) {
            if (ratingNumber == 3) {
                name = StarRatingConstants.starEmpty;
            } else if (ratingNumber == 3.5) {
                name = StarRatingConstants.starHalf;
            }
        } else if (i == 4) {
            if (3 <= ratingNumber && 4.5 > ratingNumber) {
                name = StarRatingConstants.starEmpty;
            } else if (ratingNumber == 4.5) {
                name = StarRatingConstants.starHalf;
            }
        }

        stars.push(
            <Icon key={i} name={name} size={starSizes[i]} color={color}/>
        )
    }

    return (
        <div className={`ob-star-rating ${className}`}>
            {stars}
        </div>
    );
}

StarRating.propTypes = {
    ratingNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    color: PropTypes.string,
    className: PropTypes.string,
    smallSize: PropTypes.number,
    mediumSize: PropTypes.number,
    largeSize: PropTypes.number,
}
