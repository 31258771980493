import {SearchBlockConstants} from '../../adapters/helpers/Constants';
import React from 'react';
import PropTypes from 'prop-types';

import Backdrop from '../Backdrop/Backdrop'
import Eyebrow from '../Eyebrow/Eyebrow';
import Heading from '../Heading/Heading';
import SearchBar from '../SearchBar/SearchBar';
import ErrorShortcut from './ErrorShortcut';
import '../Button/Button.scss';
import './ErrorBlock.scss';

export default function ErrorBlock({document}) {
    const eyebrow = document?.fields?.eyebrow?.fields?.text;
    const heading = document?.fields?.heading?.fields?.text;
    const commonSearchesLabel = document?.fields?.commonSearchesLabel?.fields?.text;
    const body = document?.fields?.body;
    const searchTitle = document?.fields?.searchTitle?.fields?.text;
    const searchPlaceholder = document?.fields?.searchPlaceholder?.fields?.text;
    const shortcutsTitle = document?.fields?.shortcutsTitle?.fields?.text;

    const searchActionTitle = document?.fields?.searchActionTitle?.fields?.text;

    const desktopBackground = document?.fields?.backgroundAsset;
    const mobileBackground = document?.fields?.mobileBackgroundAsset;

    const hasSearchBar = document?.fields?.hasSearchBar;

    const searchLink = document?.fields?.searchPageLink?.fields?.pagePath?.fields?.slug ?
        document?.fields?.searchPageLink?.fields?.pagePath?.fields?.slug : '';

    const shortcuts = document?.fields?.shortcuts?.map(shortcut =>
            ({
                title: shortcut?.fields?.title,
                link: shortcut?.fields?.pagePath?.fields?.slug
            })
        );

    const handleSearch = (keywords) => {
        if (keywords && keywords.length > 2) {
            window.location = searchLink + '#' + SearchBlockConstants.queryHashName + SearchBlockConstants.queryHashEqual + keywords;
        }
    };

    return (
        <Backdrop className='ob-error-block'>
            <Backdrop.Image image={desktopBackground} mobileImage={mobileBackground} />

            <Backdrop.Content className='ob-error-block-content-wrapper-1'>
                <div className='ob-error-block-content-wrapper-2'>
                    <div className='ob-error-block-content-wrapper-3'>
                        <div className='ob-error-block-content'>
                            {!!eyebrow && <Eyebrow className='ob-error-block-eyebrow'>{eyebrow}</Eyebrow>}
                            {!!heading && <Heading tag="h1" className='ob-error-block-heading'>{heading}</Heading>}
                            {!!body && <div className='ob-error-block-body' dangerouslySetInnerHTML={{__html: body}}></div>}

                            {hasSearchBar !== false &&
                                <label className='ob-error-block-search' htmlFor={SearchBlockConstants.searchText}>
                                    <div className='ob-error-block-search-bar'>
                                        <SearchBar onSearch={handleSearch}
                                                   defaultValue={''}
                                                   placeholder={searchPlaceholder}
                                                   searchActionTitle={searchActionTitle}
                                                   commonSearchesLabel={commonSearchesLabel}
                                                   isAutocompleteEnabled={false}
                                                   searchTitle={searchTitle ? searchTitle : ''}
                                            />
                                    </div>
                                </label>
                            }

                            {Array.isArray(shortcuts) && shortcuts.length > 0 && <div className='ob-error-block-shortcuts-wrapper'>
                                {!!shortcutsTitle && <div className='ob-error-block-shortcuts-title'>
                                    {shortcutsTitle}
                                </div>}
                                <div className="ob-error-block-shortcuts">
                                    {shortcuts.map((shortcut, i) => <ErrorShortcut key={i} {...shortcut}/>)}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </Backdrop.Content>
        </Backdrop>
    )
}

ErrorBlock.propTypes = {
    document: PropTypes.object,
    title: PropTypes.string,
    link: PropTypes.string,
}
