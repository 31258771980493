import Image from '../../components/Image/Image';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Block from '../../adapters/helpers/Block';
import './AwardsBlock.scss';
import { AwardsBlockConstant } from '../../adapters/helpers/Constants'

class AwardsBlock extends Component {
    constructor(props) {
        super(props);
        this.block = new Block(props);
    
        this.classNames = 'ob-awards-block';
        if (this.block.getFieldValue('classNames')) {
            this.classNames += ' ' + this.block.getFieldValue('classNames');
        }
        this.anchorId = this.block.getAnchorId();
        this.awards = this.block.getFieldValue('awards');
    }
    
    renderAward(block, index) {
        const awardBlock = new Block(block);
    
        return (
            <div key={index} className={'ob-awards-block__award-item'}>
                <div key={index} className={'ob-awards-block__award-item-content'}>
                    <div className={'ob-awards-block__award-item-logo'}>
                        <a href={awardBlock.getFieldValue('ctaLink')} target={'_blank'} aria-label={AwardsBlockConstant.awardItemLogo}>
                            <Image image={awardBlock.getFieldValue('logo')} />
                        </a>
                    </div>
                    <div className={'ob-awards-block__award-item-title'}>
                        <a href={awardBlock.getFieldValue('ctaLink')} target={'_blank'} aria-label={`${AwardsBlockConstant} ${awardBlock.getFieldValue('title')}`}>
                            {awardBlock.getFieldValue('title')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div id={this.anchorId} className={this.classNames}>
                <div className={'ob-awards-block__wrapper'}>
                    { this.awards && this.awards.map((block, index) => {
                        return this.renderAward(block, index);
                    })}
                </div>
            </div>
        );
    }
}

AwardsBlock.propTypes = {
    extraAttributes: PropTypes.object,
};

export default AwardsBlock;