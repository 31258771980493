import React from 'react';
import C from 'classnames';
import PropTypes from 'prop-types';
import './Paging.scss';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';

export default function Paging({
                                   hasPrevious,
                                   onPreviousClick,
                                   hasNext,
                                   onNextClick,
                                   onPageChange,
                                   currentPage,
                                   totalPages,
                                   countBetweenLabel,
                                   showPageNumbers,
                                   nextPageLabel,
                                   previousPageLabel,
                                   pageLabel
                               }) {
    
    const MAX_PAGE_NUMBERS_VISIBLE = 5;
    
    const onPageClicked = (page) => {
        if (onPageChange) {
            onPageChange(page);
        }
    };
    
    const getPageNumbers = () => {
        let {pagesToIncrement, pagesToDecrement} = getPageToIncrementAndDecrement();
    
        let pageIndexes = getPageIndexes(pagesToIncrement, pagesToDecrement);
        
        if (pageIndexes.length === MAX_PAGE_NUMBERS_VISIBLE) {
            if (pageIndexes[pageIndexes.length - 1] < totalPages) {
                pageIndexes[pageIndexes.length - 1] = -1;
                pageIndexes.push(totalPages);
            }
            if (pageIndexes[0] > 1) {
                pageIndexes[0] = -1;
                pageIndexes.unshift(1);
            }
        }
        
        return <div className={'ob-paging__page-numbers'}>
            {pageIndexes.map((page, index) => {
                if (page <= 0) {
                    return <span key={'ob-paging__page-spacer-' + index}>...</span>
                } else {
                    return <button className={`event_button_click ob-paging__page-number${page === currentPage ? ' active' : ''}`}
                                       data-action-detail={page}
                                       key={'ob-paging__page-number-' + page}
                                       onClick={() => onPageClicked(page)}>
                            <span className="visuallyhidden">{pageLabel}</span>
                            {page}
                        </button>
                }
            })}
        </div>
    };
    
    const getPageToIncrementAndDecrement = () => {
        let pagesToDecrement = currentPage - 1;
        if (pagesToDecrement > 2) {
            pagesToDecrement = 2;
        }
        let pagesToIncrement = totalPages - currentPage;
        if (pagesToIncrement > 2) {
            pagesToIncrement = 2;
        }
    
        let remainingPages = MAX_PAGE_NUMBERS_VISIBLE - pagesToDecrement - pagesToIncrement - 1;
        if (pagesToDecrement < pagesToIncrement) {
            pagesToIncrement = pagesToIncrement + remainingPages;
            if (currentPage + pagesToIncrement > totalPages) {
                pagesToIncrement = totalPages - currentPage;
            }
        } else if (pagesToDecrement > pagesToIncrement) {
            pagesToDecrement = pagesToDecrement + remainingPages;
            if (currentPage - pagesToDecrement < 1) {
                pagesToDecrement = currentPage - 1;
            }
        }
        
        return {pagesToIncrement, pagesToDecrement};
    };
    
    const getPageIndexes = (pagesToIncrement, pagesToDecrement) => {
        let pageIndexes = [];
    
        for (let i = currentPage - pagesToDecrement; i < currentPage; i++) {
            pageIndexes.push(i);
        }
    
        pageIndexes.push(currentPage);
    
        for (let i = currentPage + 1; i <= currentPage + pagesToIncrement; i++) {
            pageIndexes.push(i);
        }
        
        return pageIndexes;
    };
    
    return (
        <div className="ob-paging">
            {totalPages > 1 &&
                <button onClick={onPreviousClick}
                        disabled={!hasPrevious}
                        className={C('ob-paging-button', {'ob-paging-button--disabled': !hasPrevious})}
                        data-action-detail={AnalyticsConstants.previousPage}>
                    <span className="ob-paging-icon icon-chevronleft"/>
                    <span className="visuallyhidden">{previousPageLabel}</span>
                </button>
            }
            
            {showPageNumbers ? (
                    getPageNumbers()
                ) :
                (
                    <span className="ob-paging-middle">
                        {currentPage} {countBetweenLabel} {totalPages}
                    </span>
                )
            }
            {totalPages > 1 &&
                <button onClick={onNextClick}
                        disabled={!hasNext}
                        className={C('event_button_click ob-paging-button', {'ob-paging-button--disabled': !hasNext})}
                        data-action-detail={AnalyticsConstants.nextPage}>
                    <span className="ob-paging-icon icon-chevronright"/>
                    <span className="visuallyhidden">{nextPageLabel}</span>
                </button>
            }
        </div>
    )
}

Paging.propTypes = {
    hasPrevious: PropTypes.bool,
    hasNext: PropTypes.bool,
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onPageChange: PropTypes.func,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    countBetweenLabel: PropTypes.string,
    showPageNumbers: PropTypes.bool,
    nextPageLabel: PropTypes.string,
    previousPageLabel: PropTypes.string,
    pageLabel: PropTypes.string
}