import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getReviewAndRatingId} from '../../adapters/helpers/Utils';
import { handleFindElement } from '../../adapters/helpers/Utils';
import { UserQuoteConstants } from '../../adapters/helpers/Constants';
import ProductSEO from './ProductSchema';


function CustomerReview(props) {

    const reviewAndRatingId = getReviewAndRatingId(props?.product?.productOverview);
    useEffect(() => {
        document.addEventListener('DOMContentLoaded', handleFindElement());
        return () => document.removeEventListener('DOMContentLoaded', handleFindElement());
        
    },[]);


    return (
        <>
            { props.isDisplay &&
                <div className='ob-customer-review'>
                   
                    {reviewAndRatingId &&
                        <div className='ob-customer-review-container'>
                            <h2 className='ob-customer-review-title'>{props.title}</h2>

                            <div data-bv-show={UserQuoteConstants.ratingSummary} data-bv-product-id={reviewAndRatingId}></div>

                            <div data-bv-show={UserQuoteConstants.reviews} data-bv-product-id={reviewAndRatingId}></div>

                            <div data-bv-show={UserQuoteConstants.questions} data-bv-product-id={reviewAndRatingId}></div>
                        </div>
                    }
                </div>
            }
            <ProductSEO
                productDetails={props?.product?.productOverview?.fields}
            /> 
        </>
    )
}

CustomerReview.propTypes = {
    product: PropTypes.object,
    productName: PropTypes.string,
    title: PropTypes.string,
    isDisplay: PropTypes.bool,
}


export default CustomerReview
