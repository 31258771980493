import './ProductGridBlock.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../adapters/helpers/Block';
import BodyText from '../BodyText/BodyText';
import Eyebrow from '../Eyebrow/Eyebrow';
import Heading from '../Heading/Heading';
import ProductsOverview from '../ProductsOverview/ProductsOverview';
import { ProductGridBlockConstants } from '../../adapters/helpers/Constants';
import Disclaimer from '../Disclaimer/Disclaimer';

export default function ProductGridBlock(props) {
    const {extraAttributes, document} = props;

    const relatedProducts = extraAttributes?.entity?.relatedProducts;
    const products = (relatedProducts) ? relatedProducts :
        (document?.fields?.productList) ? document.fields.productList : [];

    return (products) ?
        renderProductGridBlock(products, props, !!relatedProducts) :
        '';
}

function renderProductGridBlock(products, props, isCarrousel) {
    const propsBlock = new Block(props);

    const title = propsBlock.getFieldValue(ProductGridBlockConstants.title);
    const titleLevel = propsBlock.getFieldValue(ProductGridBlockConstants.titleLevel) || ProductGridBlockConstants.two;
    const blockClassNames = propsBlock.getFieldValue(ProductGridBlockConstants.classNames);
    const anchorId = propsBlock.getFieldValue(ProductGridBlockConstants.anchorId);
    const eyebrow = propsBlock.getFieldValue(ProductGridBlockConstants.eyebrow);
    const body = propsBlock.getFieldValue(ProductGridBlockConstants.body);
    const seeMoreLabel = propsBlock.getFieldValue(ProductGridBlockConstants.seeMoreLabel)?.fields?.text;
    const seeLessLabel = propsBlock.getFieldValue(ProductGridBlockConstants.seeLessLabel)?.fields?.text;
    const itemsPerLine = propsBlock.getFieldValue(ProductGridBlockConstants.maxNumberInlineItems);
    const disclaimer = propsBlock.getFieldValue(ProductGridBlockConstants.disclaimer);
    const removeLazyLoad = propsBlock.getFieldValue(ProductGridBlockConstants.removeLazyLoad);
    let whiteText = false;

    const classNames = ['ob-product-grid-block'];

    if(blockClassNames) {
        classNames.push(blockClassNames);
    }

    if (blockClassNames && blockClassNames.indexOf(ProductGridBlockConstants.whiteText) != -1) {
        whiteText = true;
    }

    const radialBackground = propsBlock.getFieldValue(ProductGridBlockConstants.radialBackground);

    const style = {
        bg: {
            backgroundColor: propsBlock.getFieldValue(ProductGridBlockConstants.background)
        }
    }

    const radialStyle = {
        bg: {
            background: radialBackground
        }
    }

    if (products.length === 0) {
        return null;
    }

    return (
        <div className={classNames.join(' ')} id={anchorId} style={style.bg ? style.bg : null}>
            {!!radialBackground && <span className="radial-background"  style={radialStyle.bg ? radialStyle.bg : null}></span>}
            <div className="ob-product-grid-block-wrapper">
                {!!eyebrow && <Eyebrow className="ob-product-grid-block__eyebrow">{eyebrow}</Eyebrow>}
                {!!title && <Heading className="ob-product-grid-block__heading" tag={`h${titleLevel}`} whiteText={whiteText}>{title}</Heading>}
                {!!body && <BodyText className="ob-product-grid-block__body-text" whiteText={whiteText}>{body}</BodyText>}
                {Array.isArray(products) && products.length > 0 &&
                    <div className="ob-product-grid-block__product-grid-wrapper">
                        <ProductsOverview
                            whiteText={whiteText}
                            itemsPerLine={itemsPerLine}
                            seeMoreProductsText={seeMoreLabel}
                            seeLessProductsText={seeLessLabel}
                            isCarrousel={isCarrousel}
                            products={products}
                            removeLazyLoad={removeLazyLoad}
                            onClickCallback={props.onClickCallback} />
                        {disclaimer &&
                            <Disclaimer document={disclaimer} />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

renderProductGridBlock.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
}
