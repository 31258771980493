import {RichTextLinkBlockConstants} from '../../adapters/helpers/Constants';
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import React from 'react'
import PropTypes from 'prop-types'
import './RichTextLink.scss'

function RichTextLink(props) {
    const block = new Block(props);
    const image = block.getFieldValue(RichTextLinkBlockConstants.image);
    const linkType = block.getFieldValue(RichTextLinkBlockConstants.linkType);
    const title = block.getFieldValue(RichTextLinkBlockConstants.title);

    return (
        <div className={'ob-rich-text-link'}>
            <a href={block.getFieldValue(RichTextLinkBlockConstants.url)}
                target={linkType === 'external' ? '_blank' : null }
            >
                {image ? <Image image={image} /> : <>{title}</>}
            </a>
        </div>
    )
}

RichTextLink.propTypes = {
    /**
    * The Link type (external, internal, social)
    */
    linkType: PropTypes.string,

    /**
     * Image used in the link
     */
    image: PropTypes.object,
    
    /**
     * The url the link points to
     */
    url: PropTypes.string,
}

export default RichTextLink;

